.rarity-header .container,
.rarity-report-content .container,
.container-head {
  max-width: 1376px !important;
  width: 100% !important;
}



.rarity-header .container
.header-argyle-rarity-save-report  {
  max-width: 920px !important;
  width: 100% !important;
  margin: auto;
}

.share-rarity-bottom{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* padding: 60px 30.81px 60px 30.81px; */
  margin-top: -20px;
  min-height: 200px;

}

.share-rarity-bottom-text{
  width: 50%;
}

.share-rarity-bottom-logo{
  width: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  height: 153px;
  justify-content: center;
  padding: 18px 50px 45px;
}

.share-rarity-bottom-logo img{
  object-fit: contain;
  width: 180px;
  height: 100%;
}


.container-head {
  margin: auto;
}
.giaNum-mt {
  margin-top: 35px;
}
.rarity-report-page {
  background: #fbfcff;
  padding-bottom: 75px;
}
.report-head-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-wrap: wrap;
  row-gap: 10px;
}

.report-head-start {
  justify-content: flex-start !important;
}

.report-head-wrapper {
  width: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.report-head-content h2 {
  font-size: 26px;
  font-weight: 500;
  line-height: 1.62;
  color: #1b2653;
  margin: 0;
}
.dotColor {
  color: #a49d9d7a !important;
}
span.last-update {
  color: #1b2653;
  font-size: 16px;
  font-weight: 400;
  margin: -5px 0 0;
}

.rarity-report-head {
  padding: 100px 0 30px;

}

.rarity-dropdown .dropdown-toggle::after {
  display: none;
}

.rarity-dropdown button {
  padding: 0;
  background: transparent;
  border: transparent;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.rarity-dropdown .btn-check:checked + .btn,
.rarity-dropdown .btn:hover,
.rarity-dropdown .btn:focus-visible,
.rarity-dropdown .btn.active,
.rarity-dropdown .btn.show,
.rarity-dropdown .btn:first-child:active,
.rarity-dropdown :not(.rarity-dropdown .btn-check) + .btn:active {
  background: #e5e7ef;
  border-color: transparent;
  box-shadow: none;
}

.rarity-dropdown .dropdown-menu {
  padding: 8px 0;
  border-radius: 3px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
  background-color: #fff;
  border: none;
}

.rarity-dropdown a.dropdown-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  font-size: 16px;
  line-height: 26px;
  color: #0d1949;
  font-weight: 400;
}

.rarity-dropdown a.dropdown-item:hover,
.rarity-dropdown a.dropdown-item:active {
  background: #e5e7ef;
}

.rarity-tool-card {

  background: #fff;
  border-radius: 4px;

  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.rarity-card-content p.diamond-characteristics-gia {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0.16px;
}

.rarity-card-content p {
  color: #565656;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.18px;
  margin: 0;
}

.rarity-tool {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.rarity-tool-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  -moz-column-gap: 24px;
  column-gap: 24px;
}

.diamond-characteristics {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.diamond-characteristics-img {
  max-width: 104px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.diamond-characteristics-desc {
  width: 100%;
}

.rarity-card-content h6 {
  color: #000;
  margin: 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0.18px;
  text-transform: uppercase;
}

.rarity-tool-left,
.rarity-tool-right {
  display: flex;
  flex-direction: column;
  gap: 40px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
  padding: 20px 40px 0px 40px;
}

.rarity-tool-text {
  font-size: 12px;
  line-height: 18px;
  color: #1b2653;
  font-weight: 400;
  display: none;
}

.company_logo {
  width: 180px;
  height: 50px;
}
.company_logo img {

  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  -o-object-position: left;
  object-position: left;
}
.company_logo .imgstyle {
  margin-top: 0px;
}

.issue_for {
  font-size: 12px;
  line-height: 18px;
  color: #1b2653;
  font-weight: 400;
}

.rarity-page-header img {
  display: block;
  width: 150px;
  margin: 0 auto;
}
.periodic-range-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0 0 0;
}

.periodic-range-content span {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.rarity-periodic-wrapper {
  min-height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.rarity-periodic-wrapper .input-range__label--min,
.rarity-periodic-wrapper .input-range__label--max,
.rarity-periodic-wrapper .input-range__label--value {
  display: none;
}

.rarity-periodic-wrapper .input-range__track {
  height: 2px;
}

.rarity-periodic-wrapper .input-range__slider {
  background: #ffb734;
  border-color: #ffb734;
  box-shadow: 0 0 0 12px rgb(255 183 52 / 8%);
  position: relative;
  height: 12px;
  width: 12px;
  margin-top: -7px;
  margin-left: -7px;
}
.grade-desktop-view.rarity-img-mt .rarity-card-content {
  margin-top: 16px !important;
}

.rarity-periodic-wrapper .input-range__track--active {
  background: #3f51b5;
}

.rarity-periodic-range {
  position: relative;
}

.periodic-tooltip {
  max-width: 204px;
  width: 100%;
  padding: 8px 16px;
  border-radius: 6px;
  box-shadow: 0 20px 50px 0 rgb(49 70 93 / 20%);
  background-color: #fff;
  position: absolute;
  top: -100%;
  right: 50%;
  transform: translate(50%, -100%);
  opacity: 0;
  transition: all 0.5s;
}

.periodic-tooltip span {
  font-size: 12px;
  line-height: 22px;
  font-weight: 400;
}

.rarity-periodic-range .input-range-disc:hover + .periodic-tooltip {
  opacity: 1;
}

.rarity-tool-text.mobile-view {
  display: none;
}

.diamond-characteristics-img img {
  width: 164px;
  height: 164px;
}

.apexcharts-tooltip {
  background: #fff !important;
  color: #1b2653 !important;
  padding: 8px 16px !important;
  border-radius: 6px !important;
  box-shadow: 0 20px 60px 0 rgba(49, 70, 93, 0.2) !important;
  max-width: 204px !important;
  width: 100% !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  overflow: unset !important;
  white-space: unset !important;
  transform: translateY(-50%) !important;
}

.rarity-tool-card.report-disclaimer-wrapper {
  border: none;
  padding: 0;
  padding-top: 20px;
}

.rarity-tool-card.report-disclaimer-wrapper p {
  font-size: 24px;
}

.report-action-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  /* margin-top: 12px; */
}

.report-action-btn .btn {
  width: 220px;
}

.rarity-gemological-wrapper {
  position: relative;
}

.chart-xaxis-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 13.2px;
  position: absolute;
  bottom: -4px;
  max-width: 340px;
  right: 17px;
  min-height: 40px;
}

.chart-xaxis-wrapper.rarity-graph {
  bottom: -14px;
}

.chart-xaxis-wrapper span {
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  width: 55px;
  line-height: 1.2;
  pointer-events: none;
  color: #212529;
  display: flex;
  justify-content: center;
}

.no-outline-btn.btn {
  background: #bf8f02;
  border: none;
  border-radius: 0;
  height: 40px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
  text-transform: capitalize;
  transition: all 0.3s;
}

.no-outline-btn.btn:hover,
.no-outline-btn.btn:focus,
.no-outline-btn.btn:focus-visible,
.no-outline-btn.btn-check:checked + .no-outline-btn.btn,
.no-outline-btn.btn.active,
.no-outline-btn.btn.show,
.no-outline-btn.btn:first-child:active,
:not(.no-outline-btn.btn-check) + .no-outline-btn.btn:active {
  background: #a67d01;
  outline: 0;
  box-shadow: none;
}

.no-outline-btn.btn:disabled,
.no-outline-btn.btn.disabled {
  background: #bf8f02;
  border-color: #bf8f02;
}

.outline-btn.btn,
.outline-btn.btn:disabled {
  background: transparent;
  height: 40px;
  border: 2px solid #bf8f02;
  border-radius: 0;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 1px;
  text-align: center;
  color: #bf8f02;
  transition: all 0.3s;
}

.outline-btn.btn:hover,
.outline-btn.btn:focus,
.outline-btn.btn:focus-visible,
.outline-btn.btn-check:checked + .outline-btn.btn,
.outline-btn.btn.active,
.outline-btn.btn.show,
.outline-btn.btn:first-child:active,
:not(.outline-btn.btn-check) + .outline-btn.btn:active {
  border-color: #a67d01;
  background: transparent;
  outline: 0;
  box-shadow: none;
  color: #a67d01;
}
.scroll-up {
  position: fixed;
  right: 2%;
  bottom: 50px;
  z-index: 500;
  cursor: pointer;
}
.scroll-up svg {
  height: 60px !important;
  width: 60px !important;
}

.scroll-up-share {
  position: fixed;
  right: 2%;
  bottom: 50px;
  z-index: 500;
  cursor: pointer;
}
.scroll-up-share svg {
  height: 60px !important;
  width: 60px !important;
}
.toolcard-action-btn {
  justify-content: flex-end;
  margin-top: 10px;
}

.toolcard-action-btn {
  justify-content: flex-end;
  margin-top: 10px;
  display: grid;
  grid-template-columns: calc(40% - 7.2px) calc(40% - 7.2px) calc(20% - 7.2px);
  gap: 10px;
}

.toolcard-action-btn .btn {
  width: unset;
}

.rarity-gemological-card .apexcharts-backgroundBar:nth-of-type(1)[x="0"] {
  width: 53px;
  x: 7.53704638671875px;
}
.rarity-gemological-card .apexcharts-backgroundBar:nth-of-type(2)[x="0"] {
  width: 53px;
  x: 76.05564990234376px;
}
.rarity-gemological-card .apexcharts-backgroundBar:nth-of-type(3)[x="0"] {
  width: 53px;
  x: 144.57425341796875px;
}
.rarity-gemological-card .apexcharts-backgroundBar:nth-of-type(4)[x="0"] {
  width: 53px;
  x: 213.09285693359374px;
}
.rarity-gemological-card .apexcharts-backgroundBar:nth-of-type(5)[x="0"] {
  width: 53px;
  x: 281.6114604492187px;
}

/* **************************************** Input Range Custom ************************************ */

.input-range-wrapper {
  height: 1rem;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.input-range-track {
  width: 100%;
  height: 2px;
}

.input-range-disc {
  position: absolute;
  box-shadow: 0 0 0 12px rgb(155 155 155 / 8%);
  height: 16px;
  width: 16px;
  border-radius: 50%;
  cursor: pointer;
}

/* ********************************* See More ******************************** */
.rarity-card-content span {
  font-size: 14px;
  font-weight: 600;
  color: #bf8f02;
  cursor: pointer;
}

.report-head-content .sup-text,
.pdf-report-head .sup-text {
  font-size: 60%;
  position: relative;
  line-height: 0;
  vertical-align: baseline;
  top: -6px;
  left: 2px;
}

.pdf-report-head .sup-text {
  top: -10px;
}


.rarity-gemological-card {
  padding-bottom: 16px;
}

.report-time-stamp {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.report-time-stamp span {
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #1b2653;
}

.copy-btn-wrapper {
  position: relative;
  width: unset;
}



.copied-tooltip {
  position: absolute;
  top: 8px;
  right: 30%;
  font-size: 12px;
  font-weight: 600;
  line-height: 120%;
  color: #fff;
  background-color: #333;
  border-radius: 4px;
  padding: 6px 12px;
  transform: translate(50%, -100%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.copied-tooltip.show {
  opacity: 1;
  visibility: visible;
}

.shared-rarity-report-page .rarity-report-head {
  padding-top: 80px;
}

span.last-update {
  font-size: 16px;
  font-weight: 400;
  color: #1b2653;
  margin: 0;
  margin-top: -5px;
}

.shared-rarity-report-page .report-logo {
  margin-bottom: 10px;
  cursor: pointer;
}

.shared-argyle-report-page .agyle-disclaimer {
  margin-top: 16px;
}

.grade-card-content {
  min-height: 117px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.grade-card-content h5 {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
  line-height: 34px;
  letter-spacing: 0.01em;
}

.grade-card-content h4 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  line-height: 34px;
  letter-spacing: 0.01em;
}
.share-rarity h1 {
  font-size: 55px !important;
  margin-top: 15px !important;
  white-space: wrap !important;
}

.grade-desktop-view {
  display: block;
}

.right_grade {
  margin-top: 2px;
}

.share-rarity-grade {
  margin-top: 0.5px;
}
.share-rarity-mt {
  margin-top: 2px;
}

.grade-mob-view {
  display: none !important;
}

.dec-para-rarity {
  overflow: hidden;
  transition: all 0.5s;
}

.rarity-report-time-stamp.mobile-view {
  display: none;
}

.report-head-wrapper .report-action-btn .btn {
  width: 124px;
}

.report-head-wrapper .report-action-btn .btn svg path {
  fill: #bf8f02;
}

.rarity-heading-wrapper {
  padding-bottom: 36px;
}

.rarity-customize-bottom-png {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
}
.mobile-rarity-customize-bottom-png {
  display: none;

}
.rarity-customize-bottom-png img {
  height: 114px;
}

.mobile-rarity-customize-bottom-png p {
  margin: 0;
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px; /* 188.889% */
  letter-spacing: 0.18px;
  text-transform: lowercase;
  margin-bottom: 8px;
}
.mobile-rarity-customize-bottom-png img {
  display: block;
  margin: 0 auto;
  height: 114px;

}

.desk_view {
  display: block;
}

.phone_view {
  display: none;
}
@media screen and (max-width: 990px) {
  .rarity-customize-bottom-png {
    display: none;
  }

  .desk_view {
    display: none;
  }

  .phone_view {
    display: block;
  }
  .mobile-rarity-customize-bottom-png {
    display: block;


  }
  .mobile-rarity-customize-bottom-png img {
    display: block;
    margin: 0 auto;
    height: 114px;

  }
}
.rarity-customize-bottom-png p {
  margin: 0;
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px; /* 188.889% */
  letter-spacing: 0.18px;
  text-transform: lowercase;
  margin-bottom: 8px;
}
.rarity-heading-wrapper h1 {
  color: #000;
  font-family: Cinzel;
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: 75px; /* 117.188% */
  letter-spacing: 0.64px;
  text-transform: lowercase;
  margin: 0;
  text-wrap: nowrap;
}
.rarity-header-h1 {
  margin-bottom: 14px !important;
  margin-top: 15px !important;
}

.rarity-highlighted-box {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  padding: 40px 40px;
  background: #181f37;
}

.rarity-highlighted-box h2 {
  color: #fff;
  margin: 0;

  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 82px; /* 227.778% */
  font-variant: small-caps;
  letter-spacing: 0.36px;
}

.rarity-highlighted-box p {
  color: #fff;
  font-family: Cinzel;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;

  letter-spacing: 0.18px;
  margin-bottom: 0;
}

.rarity-time-report-no-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 120px;
}
.rarity-space {
  padding-bottom: 84.5px !important ;
}

.rarity-img-pd {
  padding-bottom: 35px !important;
}
.remarkable-bold {
  font-weight: 700 !important;
}

.rarity-time-report-no-wrapper h5 {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px; /* 188.889% */
  letter-spacing: 0.18px;
  text-transform: capitalize;
  margin: 0;
}

.rarity-time-report-no-wrapper p {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.18px;
  text-transform: capitalize;
  margin: 0;
}

.rarity-grade-heading-wrapper {

  gap: 4px;

}

.rarity-grade-heading-wrapper span {
  color: #fff;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.36px;
  font-family: Cinzel;
  text-transform: capitalize;
}
.rarity-grade-heading-wrapper h2 {
  color: #fff;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.36px;
  font-family: Montserrat;
  font-variant: small-caps;
  line-height: 58px;
  margin-bottom: 10px;
}
.rarity-grade-heading-wrapper p {
  color: #fff;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.36px;
  font-family: Montserrat;
  line-height: 58px;
  margin-bottom: 10px;
}

.rarity-left-content-alignment {
  min-height: 236px;
}

/* ***************************** Custom Graph ****************************** */

.custom-chart-section-wrapper {
  display: flex;
  gap: 2%;
  align-items: center;
  justify-content: space-between;
}

.custom-chart-main {
  display: flex;
  max-width: 200px;
  width: 100%;
  flex-direction: column;
  position: relative;
}

span.custom-chart-label {
  width: 100%;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.18px;
}
.card-height {
  height: 100% !important;
  display: flex !important;

}
.custom-chart-progress-wrapper {
  width: 100%;
  height: 21px;
  background: #e4e7f0;
  overflow: hidden;
}

.custom-chart-progress {
  width: 50%;
  height: 100%;
  background: #e4e7f0;
}

.custom-chart-ruler {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: -28px;
}

.custom-chart-ruler-point {
  padding-bottom: 10px;
  position: relative;
}

.custom-chart-ruler-point::before {
  content: "";
  position: absolute;
  height: 10px;
  width: 0;
  border: 0.2px solid #767676;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.custom-chart-point-25:before {
  left: 22% !important;
}
.custom-chart-point-75:before {
  left: 72% !important;
}

.custom-chart-ruler-point:nth-child(1)::before {
  left: 0;
  transform: unset;
}

.custom-chart-ruler-point:nth-child(5)::before {
  left: unset;
  right: 0;
  transform: unset;
}

.custom-chart-ruler-point:nth-child(even)::before {
  height: 6px;
}

.custom-chart-ruler-point {
  color: #767676;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  width: 25px;
  letter-spacing: 0.12px;
}

.rarity-gemological-wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 50px;
}

.grade-transform {
  text-transform: uppercase !important;
  font-size: 32px !important;
}

.rarity-text-size {
  font-size: 32px !important;
}

/* rarity level */

.rarity-header-section {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.rarity-tool-card-right {
  min-height: 235px;
}
.report-action-footer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.report-action-footer path,
.rarity-header-section path {
  fill: #bf8f02;
}
.report-action-footer .btn,
.rarity-header-section .btn {
  width: 124px;
}

.rarity-header-right .dropdown .btn {
  width: 30px;
}
.rarity-header-right .dropdown path {
  fill: #565656;
}

.rarity-header-right {
  width: 49%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.rarity-level-left-padding {
  padding-left: 1.5rem;
}
.rarity-level p {

  font-size: 18px;
  line-height: 25px;
  line-height: normal;
  font-weight: 400;
}
p.rarity-level-first-para {
  min-width: fit-content;
  font-size: 18px;
  line-height: 25px;
  line-height: normal;
  font-weight: 400;
  min-height: 37.5px;
}
p.rarity-level-scnd-para {

  height: 18px;
  width: 100%;
  max-width: 370px;
  min-width: auto;
  border-bottom: 0.5px dashed #000;
}
.rarity-gemological-card-mb {
  margin-bottom: 105px;
}
.rarity-gemological-card-mb-scnd {
  margin-bottom: 90px;
}

.rarity-gemological-card-mb-scnd p {
  color: #767676;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.18px;
}
.cardHight {
  min-height: unset;
}
.cardHightRight {
  min-height: unset;
}
.rar-customized-para {
  min-width: 150px;
  font-size: 18px;
  line-height: 25px;
}
.updatedrarity-tool-grid {
  display: unset;
}
.updated_rarity_pdf {
  width: 100%;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.1);
  padding: 40.37px 0px 45px 0px;
  border-radius: 6px;
  background-image: url("/public/icons/rarity-pdf-watermark.svg");
  /* opacity: 0.03; */
  background-repeat: no-repeat;
  background-size: cover;
}

.rarity_report_top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 60px 30.81px 60px 30.81px;
}

.rarity_report_top_heading {
  position: relative;
  max-width: 445px;
  margin: auto;
}

.rarity_report_top_heading h1 {
  margin: 0;
  font-family: Bodoni Moda !important;
  font-style: normal;
  font-weight: 400;
  font-size: 54px;
  line-height: 75.5px;
  letter-spacing: 1%;
  text-transform: capitalize;
  text-align: center;
  color: #000000;
  text-wrap: nowrap;
  position: relative;
  left: -50px;
}

.rarity_report_top_heading h2 {
  margin: -6px -9px;
  font-size: 10px;
  position: absolute;
  right: -100px;
  top: 0px;
}

.rarity_report_top_left {
  width: 47%;
  text-align: center;
}
.rarity_report_top_left.rarity-fcrf-logo {

}

.rarity_report_top_left .rarity_report_top_left_desc {
  width: 70%;
  margin: 75px auto 0px;
}

.rarity_report_top_left .rarity_report_top_left_desc h6 {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.01em;
  text-align: center;

}

.rarity_report_top_left .rarity_report_top_left_desc div {
  display: flex;
}


.rarity_report_top_left .rarity_report_top_left_desc div span {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.01em;
  text-align: left !important;


}

.rarity_report_top_left .rarity_report_top_left_desc p {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-align: center;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.gemological-extra-line-for-manual{
  line-height: 31px !important;
}

 .dotted-line{
  width: 100%;
  border-bottom: 3px dotted black;
  position: absolute;
  top: 15px;
  z-index: 1;
}

.dotted-line-for-manual{
  width: 100%;
  border-bottom: 3px dotted black;
  position: absolute;
  top: 19px;
  z-index: 1;
}

.rarity-gemological-text{
  background: #FBFCFF;
z-index: 2;
/* padding-right: 5px; */


}

.rarity_report_top_right {
  width: 47%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.rarity_report_diamond-desc {
  text-align: center;
  font-family: Montserrat, sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 170% */
  letter-spacing: 1%;
  color: #000;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 45px;
}

.gia_num {
  color: #767676;
}

.rarity_report_diamond-desc h6 {
  color: #000;
  text-align: center;
  font-family: Montserrat !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 1%;
  margin: 0px;
}

.rarity_report_diamond-desc p {

  margin: 0px;
}

.rarity_report_diamond-desc .highlight {
  font-weight: 600;
}
.rarity-right-title{
  position: relative;
}

.rarity_report_top_right .diamond-desc-img .rarity-right-title h4 {
  font-family: Bodoni Moda;
  font-size: 59.82px;
font-weight: 400;
line-height: 83.64px;
letter-spacing: 0.01em;
}


.rarity_report_top_right .diamond-desc-img .rarity-right-title span {
  position: absolute;
  top: 10%;
  right: -2%;
}

.rarity_report_top_right .diamond-desc-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

}

.rarity_report_top_right .diamond-desc-img img {
  flex-shrink: 0;
}
.rarity_report_top_right .diamond-desc-img .report-date{
  line-height: 54px !important;
}

.rarity-note-for-gia{
  margin-top: 55px !important;
}

.rarity_report_top_right .diamond-desc-img .report-date,
.rarity_report_top_right .diamond-desc-img .report-number,
.rarity_report_top_right .diamond-desc-img .rarity-note {
  color: #545353;
  text-align: center;
  font-family: Montserrat, sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1%;
  line-height: 34px;
  /* text-transform: uppercase; */
}

.rarity-right-img-data {
  margin-top: 55px;
}

.rarity_report_top_right .diamond-desc-img .report-date span {
  text-transform: lowercase;
}

.rarity_report_top_right .diamond-desc-img .report-number {
  line-height: 23px;
}

.rarity_report_top_right .diamond-desc-img .divider {
  width: 160.21px;
  height: 1px;
  background: #999090;
  margin: 15px 0;
}

.rarity_report_top_right .diamond-desc-img .rarity-note {
  line-height: 34px;
  letter-spacing: 0.34px;
  margin-top: 60px;
}

.rarity_report_top_right .bottom-logo img {
  height: 80px;
  object-fit: contain;
}

.updatedrarity-tool-grid .diamond-grade {
  padding: 50px 30.81px;
  background: #181f37;
  margin-top: 16px;
}

.updatedrarity-tool-grid .grade-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.updatedrarity-tool-grid .text-block {
  width: 47%;
  color: #fff;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
}

.updatedrarity-tool-grid .main-text {
  line-height: 80px;
  font-size: 32px;
  margin-bottom: 10px;
}

.updatedrarity-tool-grid .highlight-text {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 39px;
  font-variant: small-caps;
  letter-spacing: 0.4px;
}

.graph_section_rarity {
  padding: 50px 50.81px;
  display: flex;
  justify-content: space-between;
}

.graph_section_rarity-left {
  width: 47%;
}

.graph_section_text {
  margin-bottom: 50px;
  font-size: 20px;
}

.graph_section_rarity-left span.custom-chart-label {
  width: 345px;
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.2px;
}

.graph_section_chartdata {
  display: flex;
  flex-direction: column;
  gap: 60px;
}
.market-distribution-top {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.market-distribution-top .market-distribution {
  min-height: 800px;
}

.market-distribution {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.title {
  color: #767676;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 4px;
  letter-spacing: 0.2px;
  margin-top: 21px;
}

.distribution-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.distribution-text {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 360% */
  letter-spacing: 0.2px;
}
.updatedchart {
  display: flex;
  flex-direction: column;
  gap: 71px;
  min-height: 800px;
  justify-content: space-between;
}

.update-rarity-bottom {
  padding: 18px 50px 45px;
}

.update-rarity-bottom p {
  font-size: 15px;
  margin-bottom: 0px;
}
.update-rarity-bottom p a {
  text-decoration: none;
  color: #212529;
}

.divider-mt {
  margin-top: 40px;
}

.distribution-label {
  color: #000;
  text-align: right;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 360% */
  letter-spacing: 0.2px;
}

.bold {
  font-weight: bold;
}

.divider {
  margin-top: 35px;
  width: 100%;
  height: 1px;
  background: #999090;
}

.update-text {
  color: #767676;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  line-height: 45px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.2px;
  margin: 0;
}

.rarity-header-right-updated {
  width: 100%;
}



.old-rarity-report {
  display: none;
}

.rarity_report_gemological_properties {
  text-align: center;
  margin-top: 5px;
}

.rarity_report_gemological_properties h6 {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  line-height: 34px;
  color: #000000;
  margin-bottom: 0px;
}

.rarity_report_gemological_properties p {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.16px;
  color: #000000;
  margin: 0px 10px;
}

.rarity_report_gemological_properties span {
  color: #767676;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.16px;
}

.tab-distribution-text {
  width: 60%;
  display: flex;
  position: relative;
  justify-content: space-between;
}
.dotted-text {
  width: 100%;
  border-bottom: 3px dotted black;
  position: absolute;
  top: 19px;
  z-index: 1;
}

.tab-text-card {
  background: #FBFCFF;
  position: relative;
  z-index: 2;
}
/* ***************************** Custom Graph ****************************** */
@media screen and (max-width: 1375px) {
  .rarity-grade-heading-wrapper {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
  .rarity-grade-heading-wrapper span {
    line-height: 10px;
  }
}
@media screen and (max-width: 1147px) {
  .rarity-heading-wrapper h1 {
    font-size: 45px;
    line-height: 61px;
  }
}

@media screen and (max-width: 1024px) {

  .rarity-header .container
.header-argyle-rarity-save-report  {
  max-width: 728px !important;
  width: 100% !important;
  margin: auto;
}
}


@media screen and (max-width: 1265px) {
  h1 .rarity-heading-font {
    font-size: 40px;
    line-height: 45px;
  }

  .cardHight {
    min-height: 337px;
  }


}

.cursor-pointer {
  cursor: pointer;
}

.share-rarity-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media screen and (max-width: 992px) {
  .rarity_report_top_heading h1 {
    left: unset;
  }
  .shared-rarity-report-page .rarity-report-head {
    padding-top: 0px;
  }
  .tab-distribution-text {
    width: 60%;
    display: flex;
    position: relative;
    justify-content: space-between;
  }
  .dotted-text {
    width: 100%;
    border-bottom: 1px dotted white;
    position: absolute;
    top: 21px;
    z-index: 1;
    opacity: 0.5;
  }

  .tab-text-card {
    background: #181f37;
    position: relative;
    z-index: 2;
  }

  .rarity-mob-updated {
    width: 100% !important;
    box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.1) !important;
    padding: 40.37px 0px 25px 0px !important;
    border-radius: 6px !important;
    background-image: url("/public/icons/rarity-pdf-watermark.svg") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }

  .updatedrarity-tool-grid {
    display: none;
  }

  .old-rarity-report {
    display: block;
  }

  .rarity-tool-card {
    background: transparent;
  }
  .share-rarity-header h1 {
    font-size: 40px !important;

  }

  .rarity-time-report-no-wrapper h5 {
    font-size: 16px;
    margin-top: 34px;
  }
  .rarity-date {
    font-size: 16px;
    margin-top: 34px;
  }
  .rar-customized-para {
    font-size: 13px !important;
    line-height: 15px !important;
    margin-bottom: 15px !important;
  }

  .cursor-pointer {
    margin-left: -5px;
  }

  .giaNum-mt {
    margin-top: 0px;
  }
  .rarity-report-head {
    padding: 70px 0 30px;
  }
  .card-pd {
    padding: 0px 20px;
    margin: 0 auto;
  }
  .action-btn-mob {
    display: none;
  }
  .rarity-report-time-stamp.mobile-view {
    display: block;
    margin-top: -6px;
  }

  .market-distribution-top .market-distribution {
    min-height: 650px;
  }

  .rarity-tool-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 16px;
    background: #fff;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 20px 0px 40px;
  }
  .report-head-content h2 {
    line-height: 42px;
  }
  .rarity-tool-text.mobile-view {
    display: block;
  }
  .rarity-tool-text {
    display: block;
  }
  .rarity-tool-bottom-text-padding {
    padding-left: 1.25rem;
    margin-bottom: -2.5rem;
    margin-top: auto;
    padding-right: 1.25rem;
  }
  .pdf-template .rarity-tool-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    -moz-column-gap: 24px;
    column-gap: 24px;
    row-gap: unset;
  }
  .chart-xaxis-wrapper {
    max-width: 522px;
    width: 100%;
    bottom: 0;
    right: 23px;
    min-height: 27px;
  }

  .report-action-btn {
    width: 100%;
    flex-wrap: wrap;
  }

  .chart-xaxis-wrapper span {
    width: 84.33px;
  }



  .rarity-gemological-card .apexcharts-backgroundBar:nth-of-type(1)[x="0"] {
    width: 84.3325107421875px;
    x: 11.893046386718751px;
  }
  .rarity-gemological-card .apexcharts-backgroundBar:nth-of-type(2)[x="0"] {
    width: 84.3325107421875px;
    x: 120.01164990234375px;
  }
  .rarity-gemological-card .apexcharts-backgroundBar:nth-of-type(3)[x="0"] {
    width: 84.3325107421875px;
    x: 228.13025341796873px;
  }
  .rarity-gemological-card .apexcharts-backgroundBar:nth-of-type(4)[x="0"] {
    width: 84.3325107421875px;
    x: 336.24885693359374px;
  }
  .rarity-gemological-card .apexcharts-backgroundBar:nth-of-type(5)[x="0"] {
    width: 84.3325107421875px;
    x: 444.36746044921875px;
  }

  .grade-desktop-view {
    display: none;
  }

  .grade-mob-view {
    display: block !important;
  }
  .grade-card-content {
    gap: 5px;
  }

  .report-head-content .report-action-btn .btn {
    max-width: 200px;
    width: 100%;
  }
  .grade-mob-view .report-action-btn {
    flex-wrap: nowrap;
  }
  .grade-mob-view .report-action-btn .btn {
    width: 70px;
  }

  .rarity-time-report-no-wrapper.grade-mob-view {
    padding: 0px;
  }
  .grade-mob-view .rarity-header-right {
    width: 100%;
    align-items: center;
    margin-bottom: 15px;
  }

  .rarity-mob-grid {
    border-radius: 6px;
    background: #fff;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
  }
  .rarity-mob-pd {
    padding: 20px 40px 40px 40px;
  }

  .rarity-tool-left,
  .rarity-tool-right {
    padding: 0px;

  }

  .rarity-tool-left,
  .rarity-tool-right {
    background: none;
    box-shadow: none;
  }
  .report-action-footer {
    gap: 0px;
    margin-top: 15px;
  }

  .scroll-up {
    position: fixed;
    right: 0%;
    bottom: 20%;
    z-index: 500;
  }
  .scroll-up svg {
    height: 42px !important;
    width: 42px !important;
  }

  .scroll-up-share {
    position: fixed;
    right: 0%;
    bottom: 100px;
    z-index: 500;
  }
  .scroll-up-share svg {
    height: 42px !important;
    width: 42px !important;
  }
  .rarity-gemological-card-mb {
    margin-bottom: 0px;
  }
  .custom-chart-section-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .custom-chart-main:before {
    content: " ";
    height: 2px;
    width: 50%;
    background: #a4a4a4;
    position: absolute;
    bottom: -20px;
    display: flex;
    align-items: center;
    left: 25%;
    justify-content: center;
  }

  .custom-chart-main {

    max-width: 870px;
  }
  .rarity-left-content-alignment {
    min-height: unset;
  }
  span.custom-chart-label {
    padding-bottom: 30px;
  }
  .rarity-highlighted-box {
    padding: 20px 20px;
  }
  .mob-pt {
    padding-top: 40px;
  }
  .rarity-highlighted-box {
    min-height: 250px;
  }

  .rarity-highlighted-box .learn-about-text {
    font-family: Cinzel;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding-top: 1rem;
  }

  .highlight-box1 {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .highlight-box1 .text-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
  }

  .highlight-box1 h6 {
    font-size: 20px;
    color: #fff;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0.18px;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  .highlight-box1 p {
    font-size: 30px;
    font-family: Montserrat;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
  }
  .highlight-box2 {
    margin-top: 10px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .highlight-box2 .graph_section_rarity-left {
    width: 75%;
  }

  .highlight-box2 .text-block {
    gap: 20px;
  }

  .highlight-box2 .distribution-text,
  .highlight-box2 .distribution-label {
    font-size: 14px;
    color: white;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0.2px;
    margin-bottom: 0px;
  }

  .highlight-box2 .graph_section_text {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 14px;
    font-family: Montserrat;
    text-transform: capitalize;
  }
  .rarity-underline {
    text-decoration: underline;
  }

  .rarity-highlighted-box h2 {
    line-height: 50px;

  }

  .updated-mob-rarity p {
    font-size: 16px;
    text-transform: uppercase;
    color: #545353;
    font-weight: 400;
    line-height: 34px;
    text-align: center;
  }

  .update-divider {
    width: 70% !important;
    margin: 10px auto;
  }
  .rarity-share-logo{
    width: 180px;
    height: 50px;
    object-fit: contain;
    text-align: center;
  }
  .update-rarity-note {
    font-size: 14px;
    color: #545353;
    font-weight: 400;
    line-height: 30px;
    margin: 0px 10px 20px;
    text-align: center;

  }

  .rarity_report_head_top {
    position: relative !important;
    max-width: 340px;
    margin: 0px auto !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
 
  .rarity_report_head_top h2 {
    right: 0px !important;
  }

  .rarity_report_diamond-desc-mob {
    font-size: 16px;
  }

  .rarity_report_diamond-desc-mob h6 {
    font-family: Cinzel !important;
    font-size: 16px;
    font-weight: 400;
    text-decoration: underline;
    text-transform: uppercase;
    line-height: 34px;
    text-decoration-style: solid;
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;
  }
  .hide-gemological-text p {
    display: none;
  }

  .last-date {
    font-size: 14px;
  }
  .mob-rarity-level {
    list-style: none;
    padding: 0;
  }
  .mob-rarity-level p.rarity-level-scnd-para {
    border-bottom: 1px dashed #a49d9d7a;
  }
  .mob-rarity-level p {
    font-size: 13px;
  }

  .mob-rarity-level p.rarity-level-trd {

    white-space: nowrap;
  }

  .rarity-grade-heading-wrapper {
    align-items: flex-start;
    flex-direction: column;
    padding-top: 10px;
  }
  .rarity-grade-heading-wrapper span {
    font-size: 20px;
    line-height: normal;
    text-transform: uppercase;
  }

  .rarity-heading-wrapper h1 {

    font-size: 32px !important;
    margin-top: 15px !important;
    margin-bottom: 14px !important;
    text-wrap: wrap !important;
  }

  .rarity-tool-left,
  .rarity-tool-right {
    padding: 0px;
    gap: 30px;
  }
  .rarity-card-content h6,
  .rarity-card-content p.diamond-characteristics-gia,
  .rarity-card-content p {
    font-size: 14px !important;
  }
  .diamond-characteristics-img img {
    width: 114px;
    height: 114px;
  }
  .rarity-card-content p {
    line-height: 25px;
  }

  .argyle-report-update-page .rarity-card-content p {
    line-height: 25px !important;
  }
  .rarity-gemological-wrapper {
    margin-top: 30px;
  }

  .rarity-level-text-animation {
    height: 710px;
    opacity: 1;
    transition: all 0.3s ease-out;
  }

  .collapsed {
    height: 0;
    overflow: hidden;
    opacity: 0;

  }
}

@media screen and (max-width: 992px) {
  .rarity_report_top_heading  span {
    position: absolute ;
    top: 8px;
    right: 70px;
  }
  .mob-rarity-level p.rarity-level-scnd-para {
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .rarity_report_top_heading  span {
    position: absolute ;
    top: 6px;
    right: 75px;
  }
  .periodic-tooltip {
    transform: translate(50%, -100%);
  }
  .toolcard-action-btn {
    display: flex;
  }
  .report-action-btn .btn {
    width: 100%;
  }
  .chart-xaxis-wrapper {
    max-width: 223px;
    gap: 6px;
    right: 16px;
    bottom: -4px;
    width: 100%;
    min-height: 40px;
  }
  .chart-xaxis-wrapper span {
    width: 44.09px;
  }
  .chart-xaxis-wrapper.rarity-graph {
    max-width: 218px;
  }

  .report-head-content {
    flex-direction: column-reverse;
  }




  .highlight-box2 .graph_section_rarity-left {
    width: 85%;
  }

  .report-head-content .btn {
    width: 50%;
  }
  .rarity-gemological-card .apexcharts-backgroundBar:nth-of-type(1)[x="0"] {
    width: 41.598375px;
    x: 5.8664375px;
  }
  .rarity-gemological-card .apexcharts-backgroundBar:nth-of-type(2)[x="0"] {
    width: 41.598375px;
    x: 59.1976875px;
  }
  .rarity-gemological-card .apexcharts-backgroundBar:nth-of-type(3)[x="0"] {
    width: 41.598375px;
    x: 112.5289375px;
  }
  .rarity-gemological-card .apexcharts-backgroundBar:nth-of-type(4)[x="0"] {
    width: 41.598375px;
    x: 165.8601875px;
  }
  .rarity-gemological-card .apexcharts-backgroundBar:nth-of-type(5)[x="0"] {
    width: 41.598375px;
    x: 219.1914375px;
  }
}

@media screen and (max-width: 576px) {
  .rarity_report_top_heading span{
    position: absolute;
    top: 6px;
    right: 27px;
     
  }
  .chart-xaxis-wrapper.rarity-graph {
    max-width: 258px;
  }
  .chart-xaxis-wrapper {
    max-width: 260px;
  }
  span.custom-chart-label {
    font-size: 14px;
  }

  .argyle-report-update-page .argyle-custom-chart-label {
    font-size: 14px !important;
  }

  .argyle-report-update-page .diamond-charc-li,
  .tender-title,
  .argyle-report-update-page .svg-text {
    font-size: 14px !important;
  }
  .highlight-box2 .graph_section_rarity-left {
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .rarity_report_top_heading span{
    position: absolute;
    top: 6px;
    right: 20px;
     
  }
  .rarity-highlighted-box h2 {
    font-size: 30px;
  }
}

@media screen and (max-width: 408px) {
  

  .rarity-heading-wrapper h1 {
    font-size: 30px !important;
  }

  .highlight-box1 p {
    font-size: 28px;
    font-family: Montserrat;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
  }
  .rarity-tool-card,
  .report-head-content,
  .rarity-tool-text.mobile-view,
  .toolcard-action-btn,
  .shared-rarity-report-page .report-logo,
  .agyle-disclaimer,
  .report-time-stamp {
    max-width: 335px;
    width: 100%;
    margin: 0 auto;
  }
  .chart-xaxis-wrapper {
    gap: 7px;
    max-width: 225px;
  }
  .chart-xaxis-wrapper.rarity-graph {
    max-width: 225px;
    gap: 6px;
  }
  .chart-xaxis-wrapper span {
    font-size: 10px;
    width: 38.5px;
  }
  .rarity-gemological-card .apexcharts-backgroundBar:nth-of-type(1)[x="0"] {
    width: 36.450375px;
    x: 5.140437500000001px;
  }
  .rarity-gemological-card .apexcharts-backgroundBar:nth-of-type(2)[x="0"] {
    width: 36.450375px;
    x: 51.87168750000001px;
  }
  .rarity-gemological-card .apexcharts-backgroundBar:nth-of-type(3)[x="0"] {
    width: 36.450375px;
    x: 98.60293750000001px;
  }
  .rarity-gemological-card .apexcharts-backgroundBar:nth-of-type(4)[x="0"] {
    width: 36.450375px;
    x: 145.3341875px;
  }
  .rarity-gemological-card .apexcharts-backgroundBar:nth-of-type(5)[x="0"] {
    width: 36.450375px;
    x: 192.06543750000003px;
  }
}


@media screen and (max-width: 400px) {
  .rarity_report_top_heading span {
    position: absolute;
    top: 6px;
    right: 37px;
}
  .rarity-heading-wrapper h1 {
    font-size: 40px !important;
  }
 

  .rarity-heading-wrapper .rarity_report_head_top {
    font-size: 25px !important;
    max-width: 270px;
  }
  .rarity_report_head_top h2 {
    top: 10px !important;

  }

}
@media screen and (max-width: 390px) {
  .rarity_report_top_heading span {
    position: absolute;
    top: 6px;
    right: 30px;
}


}

@media screen and (max-width: 374px) {
  .rarity-tool-card {
    padding: 16px;
    max-width: 310px;
  }

  .report-head-content,
  .rarity-tool-text.mobile-view,
  .toolcard-action-btn,
  .shared-rarity-report-page .report-logo,
  .agyle-disclaimer,
  .report-time-stamp {
    max-width: 310px;
  }

  .chart-xaxis-wrapper {
    bottom: -4px;
    right: 15px;
    max-width: 200px;
  }
  .chart-xaxis-wrapper.rarity-graph {
    max-width: 215px;
    gap: 4px;
  }

  .chart-xaxis-wrapper span {
    font-size: 10px;
    width: 35.05px;
  }
  .rarity-gemological-card .apexcharts-backgroundBar:nth-of-type(1)[x="0"] {
    width: 35.046375px;
    x: 4.9424375000000005px;
  }
  .rarity-gemological-card .apexcharts-backgroundBar:nth-of-type(2)[x="0"] {
    width: 35.046375px;
    x: 49.8736875px;
  }
  .rarity-gemological-card .apexcharts-backgroundBar:nth-of-type(3)[x="0"] {
    width: 35.046375px;
    x: 94.8049375px;
  }
  .rarity-gemological-card .apexcharts-backgroundBar:nth-of-type(4)[x="0"] {
    width: 35.046375px;
    x: 139.7361875px;
  }
  .rarity-gemological-card .apexcharts-backgroundBar:nth-of-type(5)[x="0"] {
    width: 35.046375px;
    x: 184.6674375px;
  }

}
