.rarity-header .true-face-up-container{
  max-width: 920px !important;
  width: 100% !important;
}


.diary-header {
  min-height: 64px;
}

.diary-content {
 
  display: flex;
  justify-content: center;
}

.diary-content .content {
  width: calc(100% - 0px);
  background: #fbfcff;
  min-height: calc(100vh - 64px);
}

.diary-home-page .diary-content {
  padding: unset;
}

.diary-home-page .diary-content .content {
  min-height: calc(100vh - 0px);
}

.sidebar-wrapper {
  position: relative;
  max-width: 320px;
  width: 100%;
  transition: all 0.3s;
}

.sidebar-wrapper.close {
  width: 0;
}

.sidebar {
  width: 320px;
  height: 100%;
 
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14);
  background-color: #fff;
  transition: all 0.3s;
}

.sidebar.close {

  transform: translateX(-100%);
}

.sidebar-menu {
  padding: 80px 24px;
  min-height: calc(100vh - 64px);
  position: sticky;
  top: 0px;
  max-width: 320px;
  width: 100%;
}

.sidebar-menu-list {
  display: flex;
  flex-direction: column;
}

.diary-nav-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e7ef;
  cursor: pointer;
}

.diary-nav-item.hide {
  display: none;
}

.diary-nav-item.show-nav {
  display: flex;
}

.sidebar-menu-list .diary-link {
  position: relative;
  width: 100%;
  padding: 20px 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 1px;
  color: #0d1949;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s;
}

.sidebar-menu-list .diary-link:hover,
.sidebar-menu-list .diary-link.active {
  font-weight: 600;
}

.sidebar-menu-list .diary-link.active::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 64px;
  left: 0;
  top: 0;
  background-color: #bf8f02;
  transform: translateX(-24px);
}

.diary-top-header .back-btn {
  /* position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%); */
}

/* ****************************** Show Hide Toggle ********************************* */

/* Hide the default checkbox */
.diary-check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.diary-check-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  user-select: none;
}

/* Create a custom checkbox */
.checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ccc;
  transition: all 0.3s;
  /* border-radius: 5px; */
}

/* When the checkbox is checked, add a blue background */
.diary-check-container input:checked ~ .checkmark {
  background-color: #bf8f02;
  animation: pop 0.5s;
  animation-direction: alternate;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.diary-check-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.diary-check-container .checkmark:after {
  left: 50%;
  top: 56%;
  width: 0.3em;
  height: 0.6em;
  border: solid white;
  border-width: 0 0.12em 0.12em 0;
  transform: translate(-50%, -75%) rotate(45deg);
}


@keyframes pop {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

/* ***************************** Sidebar Toggle **************************** */

.sidebar-toggle-btn {
  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  right: -15px;
  top: 20px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  cursor: pointer;
  z-index: 1;
}

.sidebar-toggle-btn.close {
  right: -52px;
}

.sidebar-toggle {
  position: relative;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition-duration: 0.5s;
}

.bars-sidebar {
  width: 70%;
  height: 4px;
  background-color: #bf8f02;
  border-radius: 4px;
}

#bar2 {
  transition-duration: 0.8s;
}

#bar1,
#bar3 {
  width: 49%;
}

.sidebar-toggle.open .bars-sidebar {
  position: absolute;
  transition-duration: 0.5s;
}

.sidebar-toggle.open #bar2 {
  transform: scaleX(0);
  transition-duration: 0.5s;
}

.sidebar-toggle.open #bar1 {
  width: 60%;
  transform: rotate(45deg);
  transition-duration: 0.5s;
}

.sidebar-toggle.open #bar3 {
  width: 60%;
  transform: rotate(-45deg);
  transition-duration: 0.5s;
}

.sidebar-toggle.open {
  transition-duration: 0.5s;
  transform: rotate(180deg);
}

.diary-top-header .search-history-btn {
  position: unset;
  transform: unset;
  font-size: 12px;
  font-weight: 600;
  color: #6a77a2;
}

.diary-top-header {
  display: flex;
  min-height: 64px;
}

.diary-saved-card-img {
  display: flex;
  align-items: center;
  gap: 20px;
}

.saved-btn-rapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.search-history-btn.active span {
  color: #fff;
}

.search-history-btn.active svg path {
  stroke: #fff;
}

@media screen and (max-width: 1024px) {
  .sidebar-wrapper {
    position: fixed;
    left: 0;
    z-index: 1;
    transition: all 0.3s;
    display: none;
  }

  .sidebar-wrapper.close {
    width: 100%;
  
  }
  .sidebar.close {
    width: 100%;
    overflow: unset;
  }

  .diary-top-header .back-btn {
    position: unset;
    transform: unset;
  }
  .diary-top-header {
    justify-content: space-between;
  }
  .diary-top-header.dairy-input-page {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 767px) {
  .search-history-btn span {
    display: none;
  }
}
