.credit-spinner{
  color:#9DA2B2;
}

.rarity-dropdown-wrapper .dropdown-menu {
  border-radius: 0px 0px 3px 3px;
  background: #fff;
  box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.rarity-dropdown-wrapper .custom-toggle.btn::after {
  display: none;
}

.rarity-dropdown-wrapper .custom-toggle.btn {
  padding: 0;
}

.rarity-dropdown-wrapper .dropdown-item {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  transition: all 300ms;
}

.rarity-dropdown-wrapper .dropdown-item:hover {
  background: #fffbee;
}
