@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda:wght@400;500;600;700;800&display=swap");

html {
  overflow-x: hidden;
}

body {
  font-family: Montserrat !important;
  margin: 0;
  padding: 0;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 500;
  /* overflow-x: hidden; */
  background: #fff !important;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 0px;
}



body::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
  display: none;
}

body::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background: transparent;
}

#apexchartsbasic-bar * {
  font-family: Montserrat !important;
  font-weight: 500;
}

.container {
  max-width: 920px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  transition: all 0.3s;
}

.diamond-saturation.Faint {
  opacity: 0.2;
}

.diamond-saturation.Very.Light {
  opacity: 0.3;
}

.diamond-saturation.Light {
  opacity: 0.4;
}

.diamond-saturation.Fancy.Light {
  opacity: 0.5;
}

.diamond-saturation.Fancy {
  opacity: 0.7;
}

.diamond-saturation.Fancy.Intense {
  opacity: 0.8;
}

.diamond-saturation.Fancy.Vivid {
  opacity: 1;
}

.diamond-saturation.Fancy.Deep {
  opacity: 1;
  mix-blend-mode: multiply;
}

.diamond-saturation.Fancy.Dark {
  opacity: 1;
  mix-blend-mode: darken;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* ******************************** Pdf Report ******************************* */
.download-pdf-wrapper {
  position: absolute;
  top: -6000px;
  left: -6000px;
}

.pdf-template {
  width: 2480px;
  height: 3508px;
  padding: 132px;
}

.pdf-template .pdf-temp-wrapper {
  position: relative;
  border: solid 11px #212b57;
  width: 100%;
  height: 100%;
  max-width: 100%;
}

.pdf-template .pdf-temp-wrapper::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 2152px;
  height: 3181px;
  border: solid 4px rgba(2, 15, 69, 0.6);
  margin: 24px;
  z-index: -1;
}

.pdf-template .pdf-report-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 40px;
}

.pdf-template .pdf-report-head h1 {
  max-width: 1300px;
  width: 100%;
  font-size: 46px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #1b2653;
  margin: 0;
}

.pdf-template .pdf-logo {
  max-width: 300px;
  width: 100%;
  height: 100%;
}

.pdf-template .pdf-logo img {
  width: 300px;
}

.pdf-template .rarity-report-content {
  padding: 236px 0 120px;
  height: 100%;
}

.pdf-template .pdf-temp-container {
  max-width: 1672px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pdf-template .rarity-tool {
  gap: 32px;
}

.pdf-template .rarity-tool-grid {
  gap: 40px 64px;
}

.pdf-template .rarity-tool-left,
.pdf-template .rarity-tool-right {
  gap: 40px;
}

.pdf-template .rarity-card-content p {
  font-size: 30px;
  line-height: 1.6;
}

.pdf-template .rarity-tool-card {
  max-width: 100%;
  padding: 32px;
  border: solid 4px #717cad;
  box-shadow: none;
  border-radius: 0;
  color: #1b2653;
}

.pdf-template .rarity-card-content h6 {
  font-size: 34px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: 2px;
  color: #1b2653;
}

.pdf-template .periodic-range-content span {
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #1b2653;
}

/* .pdf-template #apexchartsbasic-bar * {
  font-size: 20px;
} */

.pdf-template .rarity-periodic-wrapper .input-range__slider {
  width: 22px;
  height: 22px;
  margin-top: -12px;
  margin-left: -8px;
}

.pdf-template .pdf-temp-content {
  width: 100%;
  height: 100%;
}

.pdf-template .pdf-temp-footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.pdf-template .pdf-footer-address {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 100%;
  gap: 16px;
}

.pdf-template .pdf-footer-address span {
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  color: #020f45;
}

.pdf-template .pdf-footer-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 26px;
}

.pdf-template .pdf-footer-logo span {
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.93;
  letter-spacing: normal;
  text-align: center;
  color: #020f45;
}

.pdf-template .diamond-characteristics-img {
  max-width: 240px;
}

.pdf-template .diamond-characteristics-img img {
  width: 240px;
  height: 240px;
}

.pdf-template .periodic-tooltip {
  display: none;
}

.pdf-chart-xaxis {
  max-width: 620px !important;
  width: 100% !important;
  bottom: -20px !important;
  right: 30px !important;
}

.pdf-chart-xaxis span {
  font-size: 20px !important;
  width: 92.36px !important;
  color: #1b2653;
}

.pdf-template .rarity-tool-card.report-disclaimer-wrapper {
  border: none;
  padding: 0;
}

.pdf-template.argyle-pdf-template .rarity-tool-card {
  padding: 32px !important;
  max-width: unset !important;
}

.pdf-template .rarity-tool-card.report-disclaimer-wrapper {
  padding: 0 !important;
}

.pdf-template .rarity-tool-card.report-disclaimer-wrapper p {
  font-size: 24px;
}

.rarity-tool-card.pdf-argyle-gemo-toolcard.pdf-gemo-toolcard {
  padding-bottom: 50px !important;
}

.rarity-tool-card.pdf-argyle-gemo-toolcard.pdf-gemo-toolcard
  .chart-xaxis-wrapper.pdf-chart-xaxis {
  bottom: -48px !important;
  min-height: 76px !important;
}

.rarity-gemological-wrapper.pdf-rarity-gemological-wrapper
  .apexcharts-backgroundBar:nth-of-type(1)[x="0"] {
  width: 92.386875px !important;
  x: 19.797187499999993px !important;
}

.rarity-gemological-wrapper.pdf-rarity-gemological-wrapper
  .apexcharts-backgroundBar:nth-of-type(2)[x="0"] {
  width: 92.386875px !important;
  x: 151.7784375px !important;
}

.rarity-gemological-wrapper.pdf-rarity-gemological-wrapper
  .apexcharts-backgroundBar:nth-of-type(3)[x="0"] {
  width: 92.386875px !important;
  x: 283.7596875px !important;
}

.rarity-gemological-wrapper.pdf-rarity-gemological-wrapper
  .apexcharts-backgroundBar:nth-of-type(4)[x="0"] {
  width: 92.386875px !important;
  x: 415.7409375px !important;
}

.rarity-gemological-wrapper.pdf-rarity-gemological-wrapper
  .apexcharts-backgroundBar:nth-of-type(5)[x="0"] {
  width: 92.386875px !important;
  x: 547.7221875px !important;
}

.carat-chart-wrapper g.apexcharts-series.apexcharts-pie-series path {
  filter: unset;
  stroke: unset;
}

.container.open {
  max-width: 1248px !important;
}


.dairy-save-report {
  max-width: 928px !important;
  width: 100% !important;
  margin: auto;
}


.report-timestamp {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #020f45;
}

.btn .spinner-border {
  width: 24px;
  height: 24px;
}

.diary-notice-modal.modal-static .modal-dialog {
  transform: scale(1) !important;
}

.rarity-header.argyle-header .container  {
  /* max-width: 920px !important; */
 width: 100% !important;
}

/************************************************************************************************************/

/*************** Media Query ******************/

/************************************************************************************************************/

@media screen and (min-width: 1400px) {
  .container {
    max-width: 920px !important;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 920px !important;
  }
  .diary-report-page .container {
    padding: 0 !important;
  }
}

@media screen and (max-width: 1024px) {
  .dairy-save-report {
    max-width: 728px !important;
    width: 100% !important;
    margin: auto;
  }
  .diary-input-page .container,
  .diary-report-page .container {
    max-width: 724px !important;
  }
  .diary-report-page .diary-content .content {
    width: 100%;
  }
}

@media screen and (min-width: 992px) {
  .container {
    max-width: 920px !important;
    padding: 0 !important;
  }
 
}
@media screen and (max-width: 992px) {
  /* .container {
    max-width: 726px !important;
    padding: 0 40px;
  } */
  .dairy-save-report {
    max-width: 646px !important;
    width: 100% !important;
    margin: auto;
  }
 .rarity-header.argyle-header .container,
  .rarity-argyle-container  {
    max-width: 920px !important;
   width: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  .rarity-header .container
  .header-argyle-rarity-save-report  {
    max-width: 646px !important;
    width: 100% !important;
    margin: auto;
  }

  .container {
    max-width: 408px !important;
    padding: 0 40px;
  }
  .diary-input-page .container {
    max-width: 408px !important;
    padding: 0;
  }

  .saved-report-page .container {
    max-width: 726px !important;
  }

  .diary-report-page .container {
    max-width: 476px !important;
    padding: 0;
  }
  #wa-widget-send-button {
    left: 20px !important;
    right: unset !important;
  }
  #apexchartsbasic-bar * {
    font-size: 9px;
  }
  .rarity-header.argyle-header .container,.rarity-argyle-container {
    max-width: 920px !important;
   width: 100% !important;
  }
}
@media screen and (max-width: 599px) {
  .diary-input-page .container,
  .diary-report-page .container {
    max-width: unset !important;
    padding: 0 16px;
  }
}
@media screen and (max-width: 576px) {
  .container {
    padding: 0 20px;
  }
  #apexchartsbasic-bar * {
    font-size: 9px;
  }
}
@media screen and (max-width: 480px) {
  .header-remove-mob {
     display: none;
  }
  .diary-input-mob{
     padding: 24px 0 !important;
  }
}