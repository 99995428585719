.loading-screen {
  width: 100vw;
  height: 100vh;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(30px);
  background-color: rgb(1 21 53 / 60%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
}

.loader-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 456px;
  width: 100%;
  padding: 64px 0;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 19px 38px 0 rgba(27, 38, 83, 0.4),
    0 15px 12px 0 rgba(27, 38, 83, 0.3);
  gap: 24px;
}

.loader-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.loader-content.alert-content {
  flex-direction: column-reverse;
}

.loader-content a {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 2px;
  text-align: center;
  color: #4f6bf9;
  text-decoration: none;
  text-transform: uppercase;
}

.loader-content p {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 2px;
  text-align: center;
  color: #1b2653;
}

.loader-content h2 {
  margin: 0;
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.62;
  letter-spacing: normal;
  text-align: center;
  color: #1b2653;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.imageAnimation img {
  width: 100px;
  height: 100px;
  animation: spinner5 1.5s linear 0s infinite normal none;
  perspective: 500px;
  backface-visibility: visible;
  -webkit-backface-visibility: visible;
}

/* For 3D transformations, the target's *parent* element needs these properties: */
.imageAnimation {
  height: 120px;
    width: 120px;
  perspective: 500px;
  perspective-origin: center;
}

.loader-gap {
  gap: 0px !important;
}

@keyframes spinner5 {
  0% {
    transform: rotate3d(0, 1, 0, 0deg);
  }
  100% {
    transform: rotate3d(0, 1, 0, 360deg);
  }
}



.login-alert-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  width: 100%;
  gap: 10px;
}

.login-alert-loader span {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.login-alert-loader-two {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  width: 100%;
  gap: 8px;
}

.login-alert-loader-two span {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
.login-alert-content-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.process-desc-text p {
  color: #333;
  max-width: 355px;
  font-weight: 600;
  width: 100%;
  font-size: 24px;
  line-height: 140%;
  padding-top: 10px;
  min-height: 112px;
}

.process-desc-text p {
  color: #333;
  max-width: 355px;
  font-weight: 600;
  width: 100%;
  font-size: 24px;
  line-height: 140%;
  padding-top: 10px;
  min-height: 112px;
}

.data-processing .process-desc-text p {
  color: #333 !important;
  max-width: 355px !important;
  font-weight: 600 !important;
  width: 100% !important;
  font-size: 24px !important;
  line-height: 140% !important;
  padding-top: 10px !important;
  min-height: 112px !important;
  text-align: center !important;
}

.data-processing h2 {
  font-weight: 500 !important;
  font-family: Montserrat !important;
  color: black;
  font-size: 2rem;
  /* font-size: calc(1.325rem + .9vw); */
}

.close-icon {
  position: absolute;
  right: -18px;
  top: -18px;
  width: 36px;
  height: 36px;
  background: #bf8f02;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .loader-wrapper {
    max-width: 288px;
  }
}
