.footer-main {
  background: #181f37;
  width: 100%;

}

.footer-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0;
  flex-wrap: wrap;
}

.footer-contact {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-social-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
}


.footer-bottom {
  background: #e7eff7;
  padding: 10px 20px;
}

.footer-bottom-content {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
}

.footer-contact span {
  font-weight: 400;
  font-size: 16px;
  line-height: 92.3%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 6px;
}

.footer-contact a,
.footer-contact a:hover,
.footer-contact a:active {
  font-weight: 400;
  font-size: 16px;
  line-height: 92.3%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  text-decoration: none;
}

.footer-contact a.contact-us-btn {
  text-transform: capitalize;
  border: 2px solid #ffffff;
  padding: 11px 22px;
}

.footer-bottom-content a,
.footer-bottom-content a:hover,
.footer-bottom-content a:active,
.footer-bottom-content span {
  color: #1b2653;
  font-size: 14px;
  font-weight: 500;
}
.footer-main.footer-hide {
  position: relative;
}

@media screen and (max-width: 991px) {
  .footer-contact {
    flex-direction: column;
    gap: 20px;
  }
}

@media screen and (max-width: 767px) {
  .footer-main {
    background: #0d1949;
    position: relative;
    width: 100%;
  }
  .footer-wrapper {
    justify-content: center;
    gap: 20px;
  }

  .footer-bottom-content {
    justify-content: center;
    gap: 8px 16px;
  }
  .footer-contact span,
  .footer-contact a,
  .footer-contact a:hover,
  .footer-contact a:active {
    font-size: 16px;
  }

  .footer-bottom-content span {
    text-align: center;
  }
  .footer-social-links {
    gap: 25px;
  }
  .footer-social-links a svg {
    width: 30px;
    height: 100%;
  }
  .footer-whatsapp {
    transform: translateY(-90%);
  }
  .footer-bottom-content a,
  .footer-bottom-content a:hover,
  .footer-bottom-content a:active,
  .footer-bottom-content span {
    font-size: 14px;
  }
  .footer-contact a.contact-us-btn {
    padding: 8px 26px;
  }
}
