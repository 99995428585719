.saved-report-page {
  background: #fbfcff;
}

.saved-report-wrapper {
  max-width: 920px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 32px;
  padding: 80px 2px 113px;
  margin: 0 auto;
  min-height: calc(100vh - 144px);
  overflow: auto;
  scroll-behavior: smooth;
}

.saved-report-wrapper::-webkit-scrollbar {
  display: none;
}

.saved-report-title h1 {
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.62;
  letter-spacing: normal;
  color: #1b2653;
  margin: 0;
}

.saved-card-wrapper {
  position: relative;
  padding: 23px 24px 23px 32px;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(14, 30, 62, 0.08);
  border: solid 1px transparent;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  cursor: pointer;
  transition: all 0.5s;
}

.saved-card-wrapper:hover {
  border-color: #4f6bf9;
}

.saved-card-wrapper.active {
  border-color: #4f6bf9;
}

.saved-card-content span {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 1px;
  color: #1b2653;
  margin: 0;
}

.saved-card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 60%;
}

.saved-card-img img {
  width: 100%;
  height: 72px;
  object-fit: contain;
}
.discover-weight-img{
  height: 54px !important;
}

.saved-card-img {
  display: flex;
  max-width: 75px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.saved-card-date {
  display: flex;
  column-gap: 5px;
  flex-wrap: wrap;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 1px;
  color: #1b2653;
}

.saved-card-data h6{
font-family: Montserrat;
font-size: 16px;
font-weight: 600;
line-height: 19.5px;
letter-spacing: 0.01em;
text-align: left;
margin-bottom: 0px;
color: #1b2653;

}

span.diamond-date-td {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 1px;
  color: #1b2653;
}

.saved-report-footer {
  width: 100%;
  padding: 20px 0;
  box-shadow: 0 -3px 8px 0 rgba(14, 30, 62, 0.12);
  background-color: #fff;
}

.saved-bottom-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.saved-bottom-wrapper .btn {
  height: 40px;
  padding: 7px 18.7px 7px 18px;
  background-color: #0b76d0;
  border-radius: 0;
  border: none;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
}

.divider {
  width: 100%;
  height: 1px;
  padding-bottom: 1px;
  background-color: rgba(27, 38, 83, 0.2);
}

.saved-report-page .footer-main {
  position: relative;
}

@media screen and (max-width: 1024px) {
  .saved-card-content {
    width: 66%;
  }

  .saved-report-wrapper {
    max-width: 728px;
  }
}

@media screen and (max-width: 768px) {
  .saved-card-content {
    width: calc( 100% - 60px );
  }
  .saved-card-img img {
    height: 60px;
}
.discover-weight-img{
  height: 42px !important;
}
}

@media screen and (max-width: 360px) {
  .saved-card-content {
    width: 100%;
  }
  .saved-card-img {
    display: none;
  }
}
