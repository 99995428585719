.argyle-report-page {
  background: #fbfcff;
  min-height: calc(100vh - 64px);
  padding-bottom: 119px;
}

/* rarity argyle container change width */
.rarity-argyle-container {
  width: 920px !important;
}

.argyle-report-page .rarity-card-content p {
  font-family: Montserrat;
  color: #565656;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.18px;
  margin: 0;

}

.argyle-report-page .rarity-card-content h3 {
  margin-bottom: 41px;
  font-family: Cinzel;
  font-size: 40.783px;
  font-style: normal;
  font-weight: 400;
  line-height: 57.044px;
  /* 133.333% */
  letter-spacing: 0.428px;
  max-width: 352px;
}

.argyle-report-page .diamond-characteristics-img img {
  width: 104px;
  height: 104px;
  margin-bottom: 42%;
}

/* ***** */

.argyle-report-page .report-logo {
  cursor: pointer;
}

.argyle-report-page .rarity-report-head {
  border-bottom: unset;
}

.argyle-report-page .rarity-tool-card {
  padding: 32px 24px;
}


.argyle-report-page .rarity-card-content {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

/* css for header */
.argyle-report-update-page .report-head-content {
  justify-content: flex-start;
  flex-direction: column;
}

.argyle-report-update-page .rarity-tool-left,
.argyle-report-update-page .rarity-tool-right {
  padding: 0px !important;
  background: none;
  box-shadow: none;
  gap: 16px;
  height: auto;
}

.argyle-report-update-page .rarity-gemological-wrapper {
  margin-top: 0px;


}

.rarity-gemological-footer {
  margin-top: 84px;
}

.rarity-gemological-footer {
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.071px;
}



.rarity-report-logo {
  display: block;
  position: relative;
  left: 0px;
  width: 244px;
  height: 86px;
  margin-top: 5px;
  margin-bottom: 15px;
  background-repeat: no-repeat;
}

.rarity-report-logo img {
  width: 75%;
  height: 75%;
}

.rarity-gemological-footer span {
 
  font-family: Montserrat;
  font-style: normal;
  letter-spacing: 0.071px;
  color: #020f45;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.argyle-report-update-page .rarity-tool-card {

    border-radius: 6px !important;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
}


.argyle-report-update-page .rarity-gemological-wrapper .chart-xaxis-wrapper {
  /* max-width: 540px; */
  width: 100%;
}

.argyle-report-update-page .rare-diamond-card {
  height: 100%;
}

.argyle-report-update-page .rarity-tool-grid {
  padding: 0;
  background: none;
  box-shadow: none;
}

/* **************** Rare Diamond ***************** */

.rarity-tool-card.rare-diamond-card {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.offered-tile {
  height: 87px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 0 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.offered-tile:last-child {
  border: none;
}

.offered-tile label {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 2px;
  color: #fff;
}

.offered-tile.offeredThreeTimes label,
.offered-tile.offeredFourTimes label {
  color: #0d1949;
}

.offered-tile.neverOffered {
  background: #181f37;
}

.offered-tile.offeredOnce {
  background: #2d3653;
}

.offered-tile.twiceOffered {
  background: #3a4564;
}

.offered-tile.offeredThreeTimes {
  background: #757c93;
}

.offered-tile.offeredFourTimes {
  background: #c5c7d1;
}

.offered-tile input {
  position: relative;
  width: 16px;
  height: 16px;
  appearance: none;
}

.offered-tile input[type="radio"]:checked::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.4);
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -8px;
}

.offered-tile input[type="radio"]:checked::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #ff8bb7;
  top: 50%;
  left: 50%;
  margin-top: -4px;
  margin-left: -4px;
  z-index: 1;
}

.rarity-card-content-box {
  background: #181F37;
}

.rarity-card-content-inner {
  /* padding: 17px 21px; */
  padding: 30px 40px 40px;
  min-height: 200px;
}

.rarity-card-content-box .rarity-card-content-inner h1 {
  color: #FFF;
  font-family: Cinzel;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 64.966px;
  letter-spacing: 0.285px;
  text-transform: capitalize;
}

.rarity-chart-info {
  margin-top: 39px;
  margin-bottom: 46px;
}

.rarity-chart-info h6 {
  color: #000;

  font-family: Montserrat;
  font-size: 15.053px;
  font-style: normal;
  font-weight: 600;
  line-height: 26.937px;
  /* 178.947% */
  letter-spacing: 0.151px;
  text-transform: uppercase;
}



.rarity-card-content-box .rarity-card-content-inner p {
  color: #FFF;

  font-family: Cinzel;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.937px;
  /* 188.889% */
  display: inline;
  letter-spacing: 0.143px;
}

.rarity-card-content-box .rarity-card-content-inner span {
  color: #FFF;

  font-family: Cinzel;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26.937px;
  /* 188.889% */
  letter-spacing: 0.143px;
}

.rarity-tool-card.rarity-levels-card {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.rarity-levels-card .rarity-card-content {
  gap: 0;
}

/* ************************** PDF Report ****************************** */
.pdf-template .offered-tile label {
  font-size: 22px;
  line-height: 120%;
}

.pdf-template .offered-tile input {
  width: 32px;
  height: 32px;
}

.pdf-template .offered-tile input[type="radio"]:checked::before {
  width: 32px;
  height: 32px;
  margin-top: -16px;
  margin-left: -16px;
}

.pdf-template .offered-tile input[type="radio"]:checked::after {
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
}

.pdf-template .offered-tile {
  height: 159.5px;
}

.argyle-pdf-template .pdf-report-head h1 {
  font-size: 40px;
}

.argyle-report-page .apexcharts-tooltip {
  text-align: center;
}

.argyle-report-page .apexcharts-tooltip b {
  font-weight: 700 !important;
}

.agyle-disclaimer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
}

.agyle-disclaimer .report-action-btn.toolcard-action-btn {
  margin-top: 0;
}

.rarity-levels-card .diamond-characteristics-desc p {
  padding-top: 7px;
}

.diamond-characteristics-desc {
  margin-bottom: 43.8px ;
  ;
}


.argyle-report-update-page .diamond-characteristics-desc {
  margin-bottom: 43.8px !important;
  ;}
.updated_raritypage .diamond-characteristics-desc {
  margin-bottom: 2px;
}
.shared-rarity-report-page .diamond-characteristics-desc  {
  margin-bottom: 0px ;
}


.argyle-report-page .rarity-gemological-card {
  padding-bottom: 16px;

}

.argyle_card_geo {
  padding: 20px 40px 20px 40px !important;
}

.argyle-report-page .company_logo {
  margin-top: -5px;
}

.argyle-gemological-card .chart-xaxis-wrapper {
  min-height: 40px;
  bottom: -2px;
}

.pdf-template .pdf-gemo-toolcard-argyle {
  padding-bottom: 50px;
}

.pdf-gemo-toolcard-argyle .pdf-chart-xaxis {
  bottom: -26px;
}

.template-right-section {
  position: relative;
  background-color: #fff;
  /* max-width: 340px; */
  width: 100%;
  height: 100%;
  /* box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.10); */
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
  padding: 40.37px 26px 0px 26px;
  border-radius: 6px;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.card-title {
  min-height: 108px;
  margin-bottom: 5px;
}

.date {
  margin: 0;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #000000;
}

.lowercase {
  text-transform: lowercase;
}

.report-number {
  font-weight: 400;
  font-size: 11px;
  line-height: 26px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #000;
  margin: 0;
}

.issued-for {
  margin-bottom: 0px;
  margin-top: 15px;
}

.issued-for p {
  font-size: 10px;
}

.logo {
  width: 90px;
  height: 30px;
  margin-top: -9px;
}

.logo img {
  height: 100%;
  width: 100%;
  object-position: left;
  object-fit: contain;
  margin-top: 0px;
}

.qr-code {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.qr-code img {
  width: 58px;
  height: 58px;
  object-fit: contain;
}

.dynamic-version {
  color: #000;
  text-align: center;
  font-size: 5px;
  font-weight: 500;
  letter-spacing: 0.27px;
  text-transform: capitalize;
  margin-right: 6px;
}

.card-text {
  margin-top: 138px;

  min-height: 60px;
  margin-bottom: 17.85px;
  padding-bottom: 14px;
  /* padding-top: 7px; */
}

.tender-title {
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26.858px; /* 178.947% */
  letter-spacing: 0.15px;
  text-transform: uppercase;
  margin-bottom: 10px;

}

.argyle-report-update-page  .fc-stamp {
  margin-bottom: -12px;
}

.issued-for-container {
  margin-bottom: 0;
  margin-top: 15px;
}

.issued-for-text {
  font-size: 10px;
  margin-bottom: 0px;
}

.logo-container {
  width: 90px;
  height: 30px;
  margin-top: -9px;
}

.comp-logo {
  width: 100%;
  height: 100%;
  object-position: left;
  object-fit: contain;
  margin-top: 0;
}

.diamond-charc-li {
  color: #000;
  font-family: Montserrat;
  font-size: 15.009px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.858px;
  /* 178.947% */
  letter-spacing: 0.15px;
  margin-bottom: 23px;

}

.argyle-report-update-page .diamond-charc-li span {
font-weight: 600;
}

.diamond-charc-l span {
  font-weight: 700;


}

.level-card {

  padding-top: 50px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.level-card {
  height: 405px;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
}

.level-card-content {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-direction: column;
  gap: 50px;
  align-items: center;
}

.svg-text {
  fill: #000;
  font-size: 16px;
  text-anchor: middle;

  font-family: Montserrat;
  font-size: 18.009px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.959px;
 
  letter-spacing: 0.15px;

}

.fc-stamp {
  display: flex;
  justify-content: center;
}

.fc-stamp img {
  width: 120px;
  height: 112px;
}

.card-container {
  height: 91%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.rect {
  fill: #FF5173;
}




.rarity-tool-right-mobile-view {
  display: none;
}

@media screen and (max-width: 1025px) {
  .argyle-report-update-page .rarity-gemological-wrapper .chart-xaxis-wrapper {
    max-width: 380px;
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .agyle-disclaimer {
    flex-direction: column;
  }

  .level-card {
    display: block;
  }

  .agyle-disclaimer .rarity-tool-text {
    display: block;
  }

  .template-right-section {
    box-shadow: none
  }

  .rarity-tool-right-mobile-view {
    display: block;
  }


  .rarity-tool-right {
    display: none;
  }
  .rarity-card-content-box {
    margin: -24px;
  }
  .argyle-report-update-page .argyle-custom-chart-main {
   max-width: unset ;
   width: 100% ;
   margin-top: 38px;
  } 

  .argyle-report-update-page .rarity-gemological-footer {
    display: none;
  }

  .argyle-report-update-page .template-right-section .header {
    display: none;
  }
  .argyle-report-update-page .date_block {
    display: block !important;

  }
  .date-text{
    font-size: 16px !important;
    font-weight: 600 !important;
  }


  .argyle-report-update-page .grade-mob-view .report-action-btn {
    padding: 0 21px;
  }

  .argyle-report-update-page .rarity-card-content h3 {
    margin-bottom: 35px;
    font-size: 38px;
  }
  .argyle-report-update-page .card-text {
    margin-top: 60px !important;
  }

  .argyle-report-update-page .template-right-section {
    padding: 0px !important;
  }
  .argyle-report-update-page .rarity-gemological-footer {
    margin-top: 40px;
  }
  .argyle-report-update-page .rarity-card-content-box {
   margin: 0px -24px;
  }
}

@media screen and (max-width: 768px) {
  .rarity-levels-card .rarity-card-content {
    gap: 8px;
  }

  .template-right-section {
    max-width: 100%;
  }

  .argyle-report-update-page .rarity-gemological-wrapper .chart-xaxis-wrapper {
    max-width: 560px;
    width: 100%;
    bottom: -15px;
  }

  .rarity-card-content-inner {
    padding: 30px 25px;
    min-height: auto;
}
  
.rarity-card-content-box .rarity-card-content-inner h1 {

  line-height: 47.966px;
}
.rarity-card-content-box .rarity-card-content-inner p {
  font-size: 14px;

}

.rarity-card-content-box .rarity-card-content-inner span {
  font-size: 14px;
}

}

@media screen and (max-width: 480px) {
  .argyle-report-update-page .rarity-gemological-wrapper .chart-xaxis-wrapper {
    max-width: 310px;
    width: 100%;
  }
 

  .argyle-report-update-page .rarity-tool-card {
    padding: 0px;
    background:none;
    border: none;
    box-shadow: none;
  }
  .argyle-report-update-page .template-right-section {
    padding: 0px !important;
  }
  .argyle-report-update-page .template-right-section {
    background: none;
  }
  
  .argyle_card_geo {
    padding: 0px !important;
   }
   .argyle-report-update-page .grade-mob-view .report-action-btn {
    padding: 0px;
   }

   .argyle-report-update-page .rarity-tool-left,
.argyle-report-update-page .rarity-tool-right {
  background: #fff !important;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25) !important;
  padding: 20px 20px !important;
}
.argyle-report-update-page .rarity-tool-left,
  .argyle-report-update-page .rarity-tool-right {
    background: #fff !important;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25) !important;
    padding: 20px 20px !important;
  }

  .argyle-report-update-page .rarity-card-content-box {
    margin: 0px -20px;
   }
}

@media screen and (max-width: 425px) {
  .argyle-report-update-page .rarity-gemological-wrapper .chart-xaxis-wrapper {
    max-width: 280px;
    width: 100%;
  }
  
}

@media screen and (max-width: 375px) {
  .argyle-report-update-page .rarity-gemological-wrapper .chart-xaxis-wrapper {
    max-width: 227px;
    width: 100%;
  }
}

.argyle-custom-chart-label {
  width: 100%;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.16px;
}

.argyle-custom-chart-main {
  display: flex;
  max-width: 200px;
  width: 60%;
  flex-direction: column;
  position: relative;
}

.argyle-map {
  position: absolute;
  top: 35%;
  left: 21%;
  max-width: 200px;
  text-align: center;
}


span.argyle-map {
  color: #000;
 
  font-family: Montserrat;
  font-size: 15.009px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.959px;
  letter-spacing: 0.15px;

  align-items: center;
  display: flex;
  justify-content: center;
}

.argyle-report-update-page .diamond-charc-li {
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.15px;
  margin-bottom: 30px;
}

.argyle-report-update-page .rarity-card-content h6 {
  color: #000;
  margin: 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 34px;
  letter-spacing: 0.18px;
  text-transform: uppercase;
}

.argyle-report-update-page .argyle-custom-chart-label {
 

  width: 100%;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.18px;
}

.argyle-report-update-page .rarity-gemological-footer { 
  letter-spacing: -0.0075px;
}

.argyle-report-update-page .template-right-section {

  padding: 20px 40px 20px 40px;
}

.argyle-report-update-page .date,.argyle-report-update-page .report-number {
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0.142px;
  text-transform: capitalize;
}

.argyle-report-update-page .report-number {
  font-weight: 400;
}
.argyle-report-update-page .dynamic-version {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 7.11px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.959px;
  letter-spacing: 0.213px;
  text-transform: capitalize;

}

.argyle-report-update-page .qr-code img {
height: 80px;
width: 80px;
}

.argyle-report-update-page .card-text { 
  margin-top: 155px;
}

.argyle-report-update-page .level-card {
  padding-top: 52px;
}

.argyle-report-update-page  .level-card-content {
gap:20px
}

.argyle-report-update-page .custom-chart-ruler-point {
  color: #767676;

font-family: Montserrat;
font-size: 9.507px;
font-style: normal;
font-weight: 400;
line-height: 20.599px; /* 216.667% */
letter-spacing: 0.095px;
width: 20px;
}

.argyle-report-update-page .custom-chart-ruler {
  top: -30px;
}

.argyle_card_text {
  max-width: 475px;
}

.argyle_card_text p{
  line-height: 30px;
}

