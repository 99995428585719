.setting-header .back-btn {
  padding-left: 40px;
}

.setting-page-view {
  padding-top: 64px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
}

.setting-sidebar {
  max-width: 300px;
  width: 100%;
  background: #2d3653;
  height: 100%;
  min-height: calc(100vh - 64px);
  position: sticky;
  top: 64px;
}

.setting-content {
  width: calc(100% - 300px);
  height: 100%;
}

.setting-sidebar-wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  padding: 60px 0;
}

.setting-sidebar-item {
  max-width: 140px;
  width: 100%;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
  text-decoration-line: none;
  transition: all 0.3s;
  cursor: pointer;
}

.setting-sidebar-item:hover,
.setting-sidebar-item.active {
  font-weight: 600;
  text-decoration-line: underline;
  text-underline-offset: 4px;
}

.diary-setting-wrapper {
  padding: 80px 0 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  min-height: calc(100vh - 64px);
  gap: 32px;
}

.diary-setting-card {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding: 30px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
}

.diary-setting-card-header {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.diary-setting-card-header h6 {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  margin: 0;
}

.diary-setting-card-header p {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
  margin: 0;
}

.diary-setting-btn-info p {
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.16px;
  margin: 0;
}

.diary-setting-btn-info p span {
  font-weight: 600;
}

.setting-no-bg-btn.btn,
.setting-no-bg-btn.btn:hover,
.setting-no-bg-btn.btn:active,
.setting-no-bg-btn.btn:focus,
.setting-no-bg-btn.btn:focus-visible,
:not(.setting-no-bg-btn.btn-check)+.setting-no-bg-btn.btn:active,
.setting-no-bg-btn.btn:first-child:active {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.16px;
  text-decoration-line: underline;
  padding: 0;
  background: unset;
  border: unset;
  box-shadow: unset;
  outline: unset;
  border-radius: 0;
}

.diary-setting-btns {
  display: flex;
  align-items: flex-start;
  gap: 20px 14px;
  flex-wrap: wrap;
}

.setting-bg-btn.btn {
  width: 204px;
  height: 44px;
  position: relative;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.16px;
  color: #181f37;
  background: transparent;
  border: 2px solid #181f37;
  box-shadow: none;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.setting-bg-btn.btn:hover,
.setting-bg-btn.btn:active,
.setting-bg-btn.btn:focus,
.setting-bg-btn.btn:focus-visible,
:not(.setting-bg-btn.btn-check)+.setting-bg-btn.btn:active,
.setting-bg-btn.btn:first-child:active {
  color: #181f37;
  background: transparent;
  border: 2px solid #181f37;
  box-shadow: none;
  border-radius: 0;
}

.setting-bg-btn.active-btn.btn,
.setting-bg-btn.active-btn.btn:hover,
.setting-bg-btn.active-btn.btn:active,
.setting-bg-btn.active-btn.btn:focus,
.setting-bg-btn.active-btn.btn:focus-visible,
:not(.setting-bg-btn.active-btn.btn-check)+.setting-bg-btn.active-btn.btn:active,
.setting-bg-btn.active-btn.btn:first-child:active {
  color: #fff;
  background: #181f37;
}

.setting-bg-btn.btn img {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
}

.diary-setting-reset-btn-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.diary-setting-btn-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.diary-setting-save-wrapper .no-outline-btn.btn {
  width: 220px;
  height: 44px;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.16px;
}

******************************** .upload-logo-thumnail {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.diary-setting-btn-wrapper .upload-logo-thumnail {
  justify-content: flex-start;
}

.upload-logo-thumnail .no-image-area {
  overflow: unset;
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 15px;
  min-height: 140px;
  justify-content: center;
}
.lbl-space{ font-size: 14px;}

.no-image-area h6 {
  font-size: 14px;
  font-weight: 600;
  color: #6B6B6B;
  margin: 0;
}

.no-image-area h6 span {
  color: #BF8F03;
}
.imageboxContainer{
  width: fit-content;
  min-width: 259px
}



.overlay {
  cursor: pointer;
      transform: translate(-100%, 0);
      display: block;
      
      background-color: #BF8F02;
      border-radius: 50%;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      transition: opacity 0.3s ease-in-out;
}
.upload-game-thumnail label {
  margin: 0;
  border: 1px dashed #ECDDB3;
  cursor: pointer;
  max-width: 300px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-icon{ width: 42px; height: 42px;}
.png-text {
  font-size: 12px;
  opacity: .5;
}
.settingloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
}
.form-control[type=file] {
  display: none;
}

.upload-game-thumnail label img {
  max-width: 100%;
  max-height: 120px;
}

@media screen and (max-width: 1024px) {
  .setting-sidebar {
    display: none;
  }

  .setting-content {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .setting-bg-btn.btn {
    width: 100%;
  }

  .diary-setting-btn-info p {
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 0.14px;
  }

  .upload-logo-thumnail {
    gap: 15px;
  }

  .upload-game-thumnail label {
    height: 180px;
   
  }

  .upload-game-thumnail label .no-image-area {
    height: 180px;
  }

  .upload-game-thumnail label .no-image-area h6 {
    font-size: 11px;
  }

  .upload-game-thumnail label img {
    height: 150px;
  }
}

@media screen and (max-width: 767px) {
  .setting-page-view .save-notification-wrapper {
    left: 50%;
    transform: translateX(-50%);
    max-width: 400px;
    justify-content: center;
  }
}

@media screen and (max-width: 480px) {
  .setting-page-view .save-notification-wrapper {
    max-width: 90%;
  }
}


