.rarity-sharePage-logo {
  width: 135px;
  height: 40px;
}
.rarity-access-page {
  background: #fbfcff;
 
  scroll-snap-type: y mandatory;
 
}

.rarity-main-page{
   min-height: calc(100vh - 64px);

}

.rarity-tab2 .rarity-argyle-page {
  background:none !important;
}

.rarity-tab2 .rarity-argyle-page .container {
  height: unset ! important;
}

.rarity-tab2 .rarity-argyle-page .rarity-access-content {
  padding: 20px 0px !important;
}


.rarity-header {
  position: absolute;
  width: 100vw;
  /* min-height: 64px; */
  top: 0;
  /* background: #fff; */
  z-index: 9;
}

.rarity-top-white-header{
  top:9px;
}

.rarity-access-page-height{
  padding-top: 64px ;

}

.rarity-top-white-header .container .rarity-top-header{
  max-width: 920px !important;
  margin: auto;
}


@media screen and (max-width: 768px) {
  .rarity-top-white-header .container .rarity-top-header{
    max-width: 338px !important;

    margin: auto;
  }
  .rarity-access-page{
    padding-top: 0px ;
  }
  .rarity-access-page-height{
    padding-top: 64px ;
  }
  
  .rarity-progress-bar{
    top :0px !important;

  }
  .rarity-tab2 .rarity-argyle-page .rarity-access-content h2 {
     font-size: 24px !important;
  }
}

.justify-content-end1{
  justify-content: flex-end !important;
}
  
.rarity-top-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}

.back-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  color: #0D1949;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  font-weight: 600;
  cursor: pointer;
}

.report-back-button{

  position: absolute;
  top: 18px;
}

.back-btn img {
  width: 20px;
  height: 21px;
}



.saved-report {
  display: none;
 
  align-items: center;
  gap: 10px;
  font-size: 12px;
  font-weight: 600;
  color: #6a77a2;
  cursor: pointer;
}

.saved-report.active {
  color: #fff;
}

.rarity-progress-bar {
  position: fixed;
  width: 100vw;
  top: 0px;
  z-index: 1;
  overflow-x: hidden;
}

.rarity-progress.progress {
  border-radius: 0;
  --bs-progress-bar-bg: #bf8f02;
  height: 8px;
  
 
}

.bar-height-none {
  height: 0px !important;
}




.disable-rarity-progress.progress{
  --bs-progress-bar-bg: transparent !important;
  



}
.rarity-access-content {
  padding: 80px 0 0;
 
}

.new-rarity-access-content{
  padding-top: 20px !important;
}

.diamond-weight-wrapper,
.diamond-weight-wrapper form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.diamond-weight-container {
  width: fit-content;
}

.diamond-weight-wrapper h5 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1px;
  font-weight: 500;
  color: #0d1949;
  margin: 0;
  text-transform: uppercase;
}

.diamond-weight {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.diamond-weight label {
  font-size: 12px;
  line-height: 32px;
  letter-spacing: 1px;
  font-weight: 500;
  color: #939cbc;
  margin: 0;
}

.diamond-weight input,
.diamond-weight select {
  max-width: 240px;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.56px;
  color: #0d1949;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  border-color: #6a77a2;
  padding: 0 0 0 8px;
  caret-color: rgb(255 191 0);
}

.diamond-weight input:hover,
.diamond-weight input:focus,
.diamond-weight input:active {
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  outline: 0;
}

.diamond-color-grid {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(6, minmax(0, 1fr));
}



.diamondBtn.btn {
  padding: 14px 0 6px;
  border: 2px solid transparent;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);
  width: 100%;
  transition: all 0.5s;
}

.diamondBtn.btn:hover,
.diamondBtn.btn:focus,
.diamondBtn.btn:active,
.diamondBtn.btn:focus-visible,
.diamondBtn.btn-check:checked + .diamondBtn.btn,
.diamondBtn.btn.active,
.diamondBtn.btn.show,
.diamondBtn.btn:first-child:active,
:not(.diamondBtn.btn-check) + .diamondBtn.btn:active {
  border-color: #bf8f02;
  background: #fff;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);
  outline: 0;
}

.diamond-color-box {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: flex-end;
}

.diamond-color-box img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.diamond-color-box span {
  font-size: 12px;
  font-weight: 500;
  color: #6a77a2;
  max-width: 180px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.diamond-shape-grid {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.diamond-color-wrapper {
  padding: 32px 0;
  border-bottom: 1px solid #bdc3d7;
}

.rarity-process-wrapper {
  padding: 0px 0;
  background: #fff;

}

.process-btn-rarity {
  padding: 20px 0 0 !important;
}

.rarity-process-content {
  display: flex;
  justify-content: flex-end;
}

.processBtn.btn {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  font-weight: 600;
  padding: 12px 50px;
  border-radius: 0;
  background: #bf8f02;
  border: 0;
  transition: all 0.5s;
  color: #fff;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.processBtn.btn .spinner-border {
  width: 1.5em;
  height: 1.5em;
}

.processBtn.btn.disabled,
.processBtn.btn:disabled,
fieldset:disabled .processBtn.btn {
  background: #bf8f02;
  border: none;
  opacity: 0.5;
}

.processBtn.btn:hover,
.processBtn.btn:focus,
.processBtn.btn:active,
.processBtn.btn:focus-visible,
.processBtn.btn-check:checked + .processBtn.btn,
.processBtn.btn.active,
.processBtn.btn.show,
.processBtn.btn:first-child:active,
:not(.processBtn.btn-check) + .processBtn.btn:active {
  border: none;
  background: #b18503;
  box-shadow: none;
  outline: 0;
}

.diamond-clarity-wrapper .diamond-color-box {
  gap: 22px;
  justify-content: flex-end;
  min-height: 104px;
}

.diamond-clarity-type {
  display: flex;
  align-items: center;
  gap: 8px;
}

.diamond-clarity-type h6 {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1px;
  font-weight: 500;
  color: #0d1949;
  margin: 0;
  text-transform: uppercase;
}

.diamond-btn-wrapper {
  position: relative;
  cursor: pointer;
  height: 118px;
}

.diamond-btn-wrapper input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  bottom: 0;
  cursor: pointer;
  height: 100%;
  left: 0;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}

.diamond-color-box {
  width: 100%;
  height: 100%;
  padding: 6px 0;
  border: 2px solid transparent;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%), 0 3px 1px -2px rgb(0 0 0 / 12%),
    0 2px 2px 0 rgb(0 0 0 / 14%);
  transition: all 0.5s;
}

.diamond-btn-wrapper input[type="radio"]:checked + .diamond-color-box {
  border-color: #bf8f02;
}

.rarity-access-content.rarity-scroll-content {
  padding-bottom: 700px;
}
.rarity-access-content h2 {
  font-weight: 500 !important;
}

.rarity-access-content h2,
.diary-input-content h2 {
  color: #182960;
  font-family: "Cinzel";
  font-size: 30px;
  line-height: 34px;
  letter-spacing: 0.5px;
  font-weight: 600;
  padding-bottom: 30px;
  margin: 0;
}

.error-text {
  font-size: 12px;
  color: #f34444;
  font-weight: 600;
  width: 100%;
  padding-top: 6px;
}

.diamond-weight.error input {
  border-color: #f34444;
}

.diamond-weight.error input:focus {
  box-shadow: 0 0 0 0.25rem rgb(243 68 68 / 25%);
}
/* ******************************************************************* */
.rarity-tab {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rarity-banner {
  justify-content: start !important;
  border-radius: 2.86px !important;
  
}

.rarity-tab .nav {
  background: #fff;
  max-width: 269px;
  height: 42px;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  border-bottom: none;
}

.rarity-tab .nav .nav-item {
  width: 100%;
}

.rarity-tab .nav-tabs .nav-link {
  border: none;
  border-radius: 0;
  margin: 0;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
  color: #939cbc;
  height: 42px;
  border-bottom: 4px solid transparent;
}

.rarity-tab .nav-tabs .nav-link.active {
  color: #bf8f02;
  border-bottom: 4px solid #bf8f02 !important;
}
.rarity-tab .nav-tabs .nav-link:hover {
  border-bottom: 4px solid transparent;
}
.rarity-gia-btn {
  width: 100%;
  display: block;
}

.rarity-gia-btn .processBtn.btn {
  width: 100%;
  height: 42px !important;
}


.new-rarity-tab .nav {
  max-width: unset !important;
  border-radius: 6px 6px 0px 0px;
}

.new-rarity-tab .nav .nav-item {
  width: 50%;
}

.new-rarity-desc {
  padding: 0px !important;
  padding-top: 0px !important;
  margin-top: -4px;
}

.gia_input input {
  width: 100% !important;
  max-width: unset !important;
}

.button_tab1_rarity .nav-tabs .nav-link.active{
  border-radius: 6px 0px 0px 0px;
}
.rarity-tab2 .nav-tabs .nav-link.active {
  border-radius: 0px 6px 0px 0px;
}

.rarity-tab1 .nav-tabs .nav-link.active {
  border-radius: 6px 0px 0px 0px;
}

.rarity-tab1.buyGiaActive .tab-content {
 padding: 50px 30px;
}

.new-rarity-tab .nav-tabs .nav-link {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.new-rarity-tab .tab-content > .active {
  display: flex !important;
  justify-content: center;
}

.argyle-rarity-form .tab-content > .active {
  justify-content: start ;
}





.buyGiaActive  .tab-content > .active {
  justify-content: start !important;
}


.giaBack {
  display: flex;
  align-items: center;
  gap:5px;
}


.giaBack svg {
  height: 12px;
  width: 12px;
}

.giaBack p {
  font-size: 16px !important;
  color:#bf8f02 !important;
  cursor: pointer;
}
.new-rarity-tab .tab-content {
  padding: 50px;
}

.generat-ortext p {
  position: relative;
  text-align: center !important ;
  color: black !important;
}

.manual_generate {
  display: flex;
  flex-direction: column;
  gap: 31px;
  margin-top: 31px;
}

.manual_generate p {
  font-size: 15px;
  color: #d5b65a;
  text-align: center !important;
  text-decoration: underline;
  cursor: pointer;
}
.generat-ortext p{
  text-decoration:none;
  cursor: default;
}
.newProcessBtn {
  margin-top: 10px;
}

.rarity-transform {
  text-transform: uppercase;
}

.generat-ortext p:before {
  content: " ";
  max-width: 110px;
  width: 100%;
  position: absolute;
  top: 44%;
  left: 0;
  border: 1px solid #dddddd;
}

.By_Gia .generat-ortext p:before,.By_Gia .generat-ortext p::after 
  {
  max-width: 65px !important;
}

.generat-ortext p::after {
  content: " ";
  max-width: 110px;
  width: 100%;
  position: absolute;
  top: 44%;
  right: 0;
  border: 1px solid #dddddd;
}

.rarity-beta-top {
  left: -88% !important;
}



.argyle-database {
  text-align: center;
  padding: 0 3%;
}
.argyle-database h3 {
  font-size: 16px;
  font-family: Montserrat !important;
  font-weight: 600;
  color: #0d1949;
  letter-spacing: 3px;
}

.argyle-database p {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #0d1949 !important;
  line-height: 22.5px !important;
}

.argyle-database button,.argyle-database button:not(.btn-check)+.btn:active {
  font-family: Montserrat !important;
  font-size: 14.95px;
  font-weight: 600;
  color: #0d1949;
  letter-spacing: 1.5%;
  color: #fff;
  background-color: #c74c64 !important;
  border-radius: 0%;
  border: 0;
  max-width: 203px;
  width: 100%;
  margin-top: 40px;
  border:1px solid #c74c64 !important;
}


.argyle-database button:hover {
  background-color :#ff5173 !important;
  border:1px solid #ff5173 !important;
  
}
 

.argyle-new-design .rarity-access-page.rarity-argyle-page {
  padding-top: 0px;

}


.rarity-tab2 .tab-content > .active {
  justify-content: flex-start;
}
.rarity-header-banner h1 { 
  padding: 20px 110px !important;
  font-variant: all-small-caps !important;
  font-size: 50px !important;
}

.tab1_rarity h1 {
  padding: 20px 85px !important;
  font-variant: all-small-caps !important;
  font-size: 50px !important;
}

.argyle-beta-top {
  left: -91% !important;
}
.rarity-tab-beta {
  position: relative;
}

.tab-beta {
  display: none;
  position: absolute;
  top: -11px;
  left: 0;
  font-size: 10px;
  font-weight: 600;
}


@media screen and (max-width: 992px) {
  .generat-ortext p:before,.generat-ortext p::after,.By_Gia .generat-ortext p:before, .By_Gia .generat-ortext p::after {
    max-width: 93px !important;
  }

  .diamond-color-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .diamond-shape-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .rarity-process-wrapper {
    padding: 20px 0;
  }
  .rarity-gia-btn {
    padding-bottom: 20px;
  }
  .new-diary-mob-banner {
        display: flex !important;
  }

 
}


@media screen and (max-width: 1024px) {
  .rarity-top-white-header .container .rarity-top-header{
    max-width: 728px !important;
    margin: auto;
  }
  
  .rarity-banner {
    height: 170px !important;
  }
  
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px){
  .rarity-top-white-header .container .rarity-top-header{
    max-width: 646px !important;
    margin: auto;
  }
  .argyle-section-for-pad{
    padding: 0px !important ;
  }
  .rarity-banner-tab2   h1 {
    font-size: 29px !important;
    transform: translateX(5%) !important;
    line-height: 42px !important;
    max-width: 340px !important;
  }
   .rarity-banner-tab1   h1 {
    font-size: 29px !important;
    transform: translateX(5%) !important;
    line-height: 42px !important;
    max-width: 340px !important;
  }


  .buyGiaActive .tab-content > .active {
 
}
 .process-btn-rarity .container,.rarity-tab2 #processBtn .container{
  max-width: 726px !important;
  padding: 0 0px !important;
}

.new-rarity-tab .diamond-weight-container {
  width: 100%;
}

.rarity-tab1.buyGiaActive .tab-content {
  padding: 50px 50px;
}

.new-rarity-tab .diamond-weight {
  max-width: 375px;
}

.center-gia-input{
  padding: 0px 120px;
}


.diamond-color-grid {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.diamond-shape-grid {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.new-rarity-access-page .container {
  max-width: 726px !important;
  padding: 0 40px !important;
}

 }


 



@media screen and (max-width: 599px) {
  .diamond-color-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .diamond-shape-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .diamond-color-wrapper {
    padding-top: 72px;
  }
  .rarity-access-content {
    padding: 80px 0 30px;
  }
  .rarity-process-wrapper {
    padding: 20px 0;
  }
  .rarity-process-wrapper {
    position: unset;
  }
  .rarity-access-content h2,
  .diary-input-content h2 {
    font-size: 26px;
    line-height: 28px;
    padding-bottom: 20px;
  }
  /* ************************************** */
  .rarity-tab .nav {
    max-width: 100%;
  }
  .rarity-tab .diamond-weight-container {
    width: 100%;
  }
  .rarity-gia-btn {
    padding-top: 35px;
    padding-bottom: 160px;
  }

  .rarity-access-content {
    padding: 80px 0 0;
  }
  .tab-beta { 
    display: flex;
  }
}
@media screen and (max-width: 768px) {
 .rarity-top-white-header{
  background: #fff;
 }

.rarity-banner-tab1 h1,.rarity-banner-tab2 h1 {
    max-width: 188px !important;
    padding: 0px !important;
    margin: 0px !important;
    font-size: 24px !important;
    font-variant: all-small-caps !important;

    line-height: 34px !important;
    transform: translateX(2%);

}

.new-rarity-tab .tab-content {
  padding: 30px 20px 20px;
}
.new-rarity-heading {
  font-weight: 500 !important;
 
}
.new-rarity-tab .nav-tabs .nav-link {
  text-wrap: nowrap;
  font-size: 14px;
}
.new-rarity-tab .diamond-weight-wrapper h5 {
  font-size: 13px;
  font-weight: 600;
}
.new-rarity-tab .diamond-weight input, .diamond-weight select {
  font-size: 15px;
}
.new-rarity-tab .rarity-gia-btn {
  padding-top: 10px;
  padding-bottom: 10px;
}
.new-rarity-tab .diamond-weight-wrapper, .diamond-weight-wrapper form {
  gap:15px ;
}
.new-rarity-tab  .manual_generate {
  gap: 15px;
  margin-top: 25px;
}

.new-rarity-tab .rarity-process-content {
  justify-content: center;
}

.rarity-tab2 .tab-content {
  padding: 30px 0px 20px !important;
}
.rarity-banner-tab2 h1 {
  /* font-size: 20px !important; */
  transform: translateX(5%) !important;
  line-height: 26px !important;
  letter-spacing: 1.2px !important;
}

.argyle-database button, .argyle-database button:hover, .argyle-database button:not(.btn-check)+.btn:active {
  margin-top: 20px;
}
.new-diamond-color-wrapper {
  padding-top: 20px;
}
.argyle_Back{
  margin-left: 20px;

}
.rarity-banner-tab1 h1 { 
  line-height: 26px !important;
}

}

@media screen and (max-width: 390px) { 
  .rarity-banner-tab1 h1 {
    font-size: 24px !important;
    transform: translateX(-2%);
    line-height: 26px !important;
  }
  .rarity-banner-tab2 h1 {
    transform: translateX(1%) !important;
  }
  .new-rarity-tab .nav-tabs .nav-link {
  
    font-size: 12px;
  }

  .generat-ortext p:before, .generat-ortext p::after, .By_Gia .generat-ortext p:before, .By_Gia .generat-ortext p::after {
    max-width: 85px !important;
  }
}
@media screen and (max-width: 363px) { 
  .new-rarity-tab .nav-tabs .nav-link {
    
    font-size: 10px;
  }
  
  .generat-ortext p:before, .generat-ortext p::after, .By_Gia .generat-ortext p:before, .By_Gia .generat-ortext p::after {
    max-width: 70px !important;
  }
  .rarity-tab2 .rarity-argyle-page .rarity-access-content h2 {
    font-size: 24px !important;
 }
}