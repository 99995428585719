b {
  font-weight: 600 !important;
}

/* header logo css */

.diamond-Storyteller:hover {
  background: #a67d01 !important;
}

.diamond-Storyteller span {
  white-space: nowrap;
}

.storyteller-beta {
  position: absolute;
  left: 13px;
  top: -15px;
}

.storyteller-beta p {
  font-weight: 500 !important;
  font-family: Montserrat;
  margin-left: -12px;
  font-size: 10px;
  line-height: 9.75px;
  color: #000000;
}

.beta-top-mob {
  display: none;
}

.beta-top {
  position: relative;
  top: -24px;
  left: -87%;
  font-size: 10px;
  font-weight: 600;
}
.report-page-hedding {
  position: relative;
}
.beta-top-report-page {
  position: absolute;
  top: -10px;
  z-index: 1;
  left: 0px;
  font-size: 10px;
  font-weight: 600;
}

.diary-title-mt {
  margin-top: 10px;
}

.dairy-banner {
  height: 218px;
  width: 100% !important;
  background-size: 100%;
  object-fit: contain;
  background-repeat: no-repeat;
  border-radius: 6px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-position: bottom ;

  /* background: round; */

}

.rarity-banner-tab2 {
  /* background: round; */
}

.rarity-banner-tab1 .new-rarity-heading {
  letter-spacing: 1.2px;
}

.dairy-banner-shadow {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);
}

.diary-mob-banner,
.new-diary-mob-banner {
  display: none;
  aspect-ratio: 1/0.7;
}

.diary-mob-banner h1 {


  line-height: 25px !important;
  font-size: 20px !important;
  letter-spacing: 2px !important;
  padding: 20px 10px !important;
  text-align: left !important;
  max-width: 271px !important;
}
.dairy-banner h1 {
  max-width: 610px;
  padding: 20px 40px;
  color: white;
  letter-spacing: 3px;
  line-height: 45px;
  font-size: 40px;
  text-align: center;
  font-weight: 400 !important;
  font-family: "Cinzel";
  font-feature-settings: "smcp", "c2sc";
  margin: 0px;
  font-variant: traditional;
}

.astric-red {
  color: red;
}

.accordian-down-header {
  display: flex;
  align-items: center;
  gap: 5px;
}
.accordian-down-header svg,
.arrow-up svg {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.arrow-up {
  display: flex;
  justify-content: center;
  position: relative;
}
.arrow-up svg {
  transform: rotate(180deg);

  margin-top: 10px;
}
.diary-input-desc-pd {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}
/* ************ */
.diary-page {
  min-height: calc(100vh - 64px);
  background-color: #fbfcff;
}
.diary-page .report-logo {
  cursor: pointer;
  margin-bottom: 10px;
}

.diary-page .report-logo img{
  width: 15%;
  height: 15%;
}

.diary-wrapper {
  padding: 80px 0 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.diary-title h1 {
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;

  letter-spacing: normal;
  margin: 0;
  color: #0d1949;
  text-transform: capitalize;
}

.diary-title .sup-text {
  font-size: 60%;
  position: relative;
  line-height: 0;
  vertical-align: baseline;
  top: -6px;
  left: 2px;
}

.diary-input-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  padding: 20px 0;
}

.scroll-up2 {
  position: fixed;
  right: 2%;
  bottom: 50px;
  z-index: 500;
  cursor: pointer;
}
.scroll-up2 svg {
  height: 60px !important;
  width: 60px !important;
}

.scroll-up-share2 {
  position: fixed;
  right: 2%;
  bottom: 50px;
  z-index: 500;
  cursor: pointer;
}
.scroll-up-share2 svg {
  height: 60px !important;
  width: 60px !important;
}

.diary-input-desc {
  background-color: #fff;
  padding: 24px;
  border-radius: 6px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);
}

.diary-input-desc p {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: normal;
  margin: 0;
  text-align: justify;
}
.diary-input-desc p a {
  color: #d5b65a;
  text-align: center !important;
  text-decoration: underline;
  cursor: pointer;
}


.diary-form-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  position: relative;
}

.diary-input-content .diary-card {
  width: 100%;
}

.diary-form-icon {
  margin-bottom: 8px;
  display: none;
}

.diary-form-content h5 {
  color: #1b2653;
  margin: 0;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 1px;
  letter-spacing: 2.5px;
}

.diamond-weight.diary-input input,
.diamond-weight.diary-input select {
  max-width: 320px;
}

.diary-input {
  max-width: 320px;
  width: 100%;
}

.diamond-weight.diary-input label {
  text-transform: uppercase;
}

.bg-btn.btn {
  max-width: 320px;
  width: 100%;
}

.bg-btn.btn:disabled {
  background-color: #bf8f02;
}

.diary-card {
  position: relative;
  padding: 64px;
  border-radius: 4px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 2px 2px 0 rgba(0, 0, 0, 0.14);
  background-color: #fff;
}

.diary-character-card {
  padding: 32px 64px;
}

.diary-character-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
}

.diary-character-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
}

.diary-character-img img {
  width: 118px;
  height: 118px;
  object-fit: contain;
}

.diary-field {
  display: flex;
  flex-direction: column;
  gap: 1px;
  align-items: flex-start;
  max-width: 205px;
  width: 100%;
}

.diary-label {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.67;
  letter-spacing: 1px;
  color: #939cbc;
}

.diary-field h6 {
  position: relative;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 0.56px;
  margin: 0;
  color: #0d1949;
}

.diary-card-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.diary-card-content img {
  width: 100%;
}

.diary-card-content p {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  margin: 0;

  color: #181f37;
}

.diary-card-content h5 {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;

  margin-top: 10px;
}

.remove-gaps {
  margin-top: 10px;
  gap: 0px;
}
.update-origin-text {
  min-width: 300px;
}

/* --- */
.diary-card-content.polished-card-content {
  flex-direction: row;
  align-items: stretch;
}

.rarity-grade {
  position: relative;
  width: 436px;
  height: 260px;
  background: #181f37;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.rarity-grade-text {
  text-transform: uppercase;
}

.text-url,
.rarity-grade p {
  color: #fff;
}

ul.rarity-grade-list {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-auto-flow: column;
  margin-bottom: 0;
  padding-left: 18px;
  font-weight: 400;
}

.rarity-general {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.a-url {
  color: #bf8f02;
  text-decoration: auto;
}

h4.rarity-title {
  max-width: 100% !important;
}

.rarity-info-mob {
  display: none;
}

/* ---- */
.shape-card-content {
  flex-direction: row;
  justify-content: space-between;
  gap: 18px;
}

.shape-card-content p {
  max-width: 388px;
  width: 100%;
}

.diary-shape-view,
.color-cause-diamond-bg,
.carat-chart-wrapper {
  display: flex;
  justify-content: center;
}

.carat-chart-wrapper {
  max-width: 300px;
  width: 100%;
  transform: translateY(-25px);
}

.diary-shape-view img {
  width: 100%;
  height: 193px;
  object-fit: contain;
}

.diary-color-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 12px 6px;
  flex-wrap: wrap;
}

.diary-color-detail {
  position: relative;
  max-width: 153px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.diary-color-detail img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.diary-color-palette {
  width: 100%;
  height: 12px;
}

.diary-color-detail span {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #6a77a2;
}

.history-card-content {
  flex-direction: row;
  gap: 16px;
  align-items: flex-start;
}

.history-card-content img {
  max-width: 388px;
  width: 100%;
  height: 344px;
  object-fit: contain;
}

.weight-card-content {
  flex-direction: row;
  justify-content: space-between;
}

.weight-card-content p {
  max-width: 267px;
  width: 100%;
}

.image-compare {
  display: flex;
  justify-content: center;
  max-width: 478px;
  width: 100%;
  align-items: flex-end;
  position: relative;
  transform: translateY(-40px);
  height: 250px;
}

.diamond-image-color img {
  width: 207px;
  height: 207px;
  object-fit: contain;
}

.diamond-image-shape img {
  width: 193px;
  height: 193px;
  object-fit: contain;
}

.image-compare::before,
.image-compare::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background: #000;
}

.image-compare::before {
  top: 43px;
}

.image-compare::after {
  bottom: 50px;
}

.diamond-image-spec {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.diamond-image-spec span {
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 1.67px;
  color: #0d1949;
}

.fluorescence-diamond-bg {
  width: 100%;
  background-color: #181f37;
  background-size: 255px;
  background-repeat: no-repeat;
  background-position: 55% 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  transform: rotate(180deg);
  transition: all 0.3s;
}

.fluorescence-card-content {
  flex-direction: row;
  gap: 16px;
}

.fluorescence-card-content p {
  max-width: 388px;
  width: 100%;
}

.fluorescence-diamond-bg img,
.fluorescence-diamond-bg svg {
  width: 193px;
  height: 193px;
  object-fit: contain;
  transform: translate(4px, 2px);
}

.clarity-grid-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.clarity-box-wrapper {
  max-width: 133px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 7px 0 11px;
  border: 1px solid #181f37;
  min-height: 104px;
  transition: all 0.3s;
}

.clarity-card-content .clarity-box-wrapper h4 {
  font-family: Cinzel;
  font-size: 40px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
  text-align: center;
  margin: 0;
  max-width: 100%;
  color: rgb(13 25 73 / 87%);
  max-width: 100%;
}

.clarity-box-wrapper span,
.index-xaxis {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #6a77a2;
}

.clarity-box-wrapper span {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
}

.carat-card-content {
  flex-direction: row;
  justify-content: space-between;
}

.carat-heading {
  max-width: 388px;
  width: 100%;
}

.carat-heading h3 {
  font-family: Cinzel;
  font-size: 40px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
  margin: 0;
  color: #0d1949;
}

.diamond-index-card-content {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.index-card-text {

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.index-card-text p span {
  font-size: 16px;
}

.index-card-text span {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #0d1949;
}



.diamond-index-chart-wrapper {
  position: relative;

  width: 100%;
}

.index-xaxis {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 14px;
  top: unset;
  left: unset;
  right: -5px;
  bottom: 24px;
}

.index-yaxis {
  flex-direction: column;
  height: 100%;
  padding: 27px 0 44px;
  width: unset;
  top: 0;
  left: -2px;
  right: unset;
  bottom: unset;
}

.index-card-text p {
  padding-top: 20px;
}

.diamond-polished-wrapper {
  position: relative;
  min-height: 120px;
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.range-label {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.polished-range {
  position: relative;
  width: 100%;
}

.polished-range-slider {
  height: 6px;
  border-radius: 9.5px;
  background: #e7e9ed;
  overflow: hidden;
}

.polished-range-wrapper {
  height: 100%;
  transition: all 0.3s;
}

.range-label span {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6a77a2;
  padding-top: 4px;
}

.polished-range img {
  position: absolute;
  top: -56px;
  left: 0;
  width: 48px;
  height: 48px;
  object-fit: contain;
  transform: translateX(-50%);
}

.colorCause-card-content {
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;

}

.colorCause-card-content p {
  max-width: 388px;
  width: 100%;
}

.color-cause-diamond-bg svg {
  width: 100%;
  height: 248px;
  object-fit: contain;
  transform: translateX(200%);
  opacity: 0;
  transition: all 1.3s;
}

.color-cause-diamond-bg img {
  width: 100%;
  height: 160px;
  object-fit: contain;
  transform: translateX(200%);
  opacity: 0;
  transition: all 1.3s;
}

.color-cause-diamond-bg.animate svg,
.color-cause-diamond-bg.animate img {
  transform: translateX(0%);
  opacity: 1;
}

.diary-card h4 {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 10px;
  max-width: 50%;
}

.diamond-polished-wrapper {
  display: none;
}

.diamond-index-chart-wrapper .apexcharts-tooltip {
  max-width: 154px !important;
}

.diary-form-content .error-text {
  position: absolute;
  bottom: -20px;
}

.diary-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 20px;
}

.diary-title .toolcard-action-btn {
  margin: 0;
  max-width: 60%;
  width: 100%;
}

.index-average-data {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
}

.index-average-data h6 {
  margin: 0;
  color: #181f37;

  font-weight: 400;
}

.rarity-tool-text a,
.rarity-tool-text a:hover {
  text-decoration: none;
  color: #1b2653;
}

/* ****************** New face up **************** */

.second-faceup-image-box {
  display: flex;
}

.second-faceup-image-box span {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 10.46px;
  position: absolute;
  left :  -29px;
  bottom: -10px;
}

.second-faceup-image{
  display: flex;
  align-items: flex-end;
  position: relative;
}



.second-faceup-image-container{
  padding: 10px 0px 30px 70px;
}

.second-faceup-inner-text{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100%;
  gap: 5px;
  margin-top: 25px;
}

.second-faceup-inner-text h5{
  font-family: Montserrat;
  font-size: 38px;
  font-weight: 600;
  line-height: 4.8px;
  text-align: center;
  margin-top: 16px;
}

.second-faceup-inner-text p{
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
}


.second-face-text-wrapper{
  width: 50%;
}

.second-face-text-wrapper h6{
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.second-faceup-card-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 50px;
}
.faceup-frame-wrapper {
  position: relative;
  width: fit-content;
  height: fit-content;
}

.faceup-card-content {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
}

.faceup-frame {
  position: relative;
  background-image: url(../../assets/images/faceupFrame.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 179px;
  width: 349px;
}



.faceup-frame-images {
  display: flex;
  flex-direction: row;
  align-items: center;

}

.true-faceup-images {
flex-direction:row-reverse;
}

.faceup-frame-images img:last-child {
  width: 170px;
  filter: grayscale(100%) opacity(50%) contrast(101%);
  -webkit-filter: grayscale(100%) opacity(50%) contrast(101%);
  -moz-filter: grayscale(100%) opacity(50%) contrast(101%);

}

.faceup-frame-images img:first-child {
  width: 200px;

}

.diamondTxt1,
.diamondTxt2,
.diamondMMheight,
.diamondMMwidth,
.diamondTxtmm {
  position: absolute;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  letter-spacing: 0.02em;
  color: #181f37;
}

.diamondTxt1 {
  top: -22%;
  max-width: 140px;
  left: 6px;
}

.diamondTxt2 {
  display: flex;
  max-width: 140px;
  top: -22%;
  right: 3%;
  text-align: left;
}

.diamondMMheight {
  left: -32%;
  top: 48%;
}

.diamondMMwidth {
  bottom: -26%;
  left: 20%;
}

.faceup-card-content p {
  max-width: 400px;
  width: 100%;
}

.readmore-text {
  font-size: 14px;
  font-weight: 600;
  color: #bf8f02;
  cursor: pointer;
}

.faceup-card-main-content {
  gap: 60px;
}

.diary-saturation-img {
  position: relative;
}

.saturation-indication {
  animation: shift-left 0.5s infinite linear;
  position: absolute;
  top: 50%;
  left: -20px;
  transform: translate(0, -50%);
}

.saturation-indication svg {
  width: 20px;
  height: 20px;
}

.colorCause-card-text {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.faceup-top {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.dec-para-cause {
  height: 0;
  overflow: hidden;
  transition: all 0.3s !important;
}

.dec-para-zero-height {
  height: 0;
}

.dec-para-full-height {
  height: 260px;
}

.diary-notice-modal .modal-header .btn-close {
  background-color: #bf8f02;
  opacity: 1;
  border-radius: 50%;
  position: absolute;
  right: -8px;
  top: -8px;
}
.report-action-gap {
  gap: 10px;
}

.diary-notice-modal .modal-header {
  padding: 0px;
  border: 0;
}

.diary-notice-modal .modal-body {
  padding: 40px;
}

.diary-notice-modal .modal-body p {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: normal;
  color: #1b2653;
}

.diary-notice-modal .modal-body p a {
  color: #bf8f02;
  text-decoration: none;
  font-weight: 500;
}

.diary-character-content.mobile {
  display: none;
}

.rarity-link {
  cursor: pointer;
  color: #0053ff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 28px;
  letter-spacing: 0.18px;
  transition: all 0.3s;
}

.rarity-link:hover {
  text-decoration: underline;
}

.diary-card.hide {
  display: none;
}

.showall-card-btn {
  display: flex;
  align-items: center;
  gap: 6px;
}

.showall-card-btn span {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 1px;
  color: #0d1949;
}

.showall-card-btn {
  display: none;
}

.famous-card-wrapper {
  max-width: 386px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.famous-card-title {
  max-width: 100%;
}

.famous-card-left {
  width: 48%;
}

.famous-card-right {
  width: 48%;
}

.mobile-hide-card-btn {
  display: none;
}

.famous-card-left .famous-card-title {
  max-width: 100%;
}

.title-long-width {
  max-width: 70% !important;
}

.diary-title .copy-btn-wrapper {
  width: unset;
}

.diary-title .copy-btn-wrapper .btn {
  width: 80px;
}

.diamond-index-chart-wrapper line[y1="256"] {
  opacity: 0;
  visibility: hidden;
}



.diary-tool-grid .report-action-btn .btn {
  width: 100px;
}
.diary-tool-grid .copy-btn-wrapper .btn {
  width: 100px;
}

.diary-tool-grid .copy-btn-wrapper {
  width: unset;
}

.diary-tool-grid .diamond_diary_btn {
  width: fit-content;
}

.diary-tool-grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
}

.diary-tool-grid .rarity-tool-left {
  max-width: 100%;
}

.outline-btn.diamond-new-report.btn svg path {
  fill: #bf8f02;
}

/* *********************** Disclaimer Modal **************************** */

.diary-notice-modal .modal-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.disclaimer-btn-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.disclaimer-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.004em;
  color: #182960;
  width: fit-content;
  cursor: pointer;
}

.disclaimer-btn.btn,
.disclaimer-btn.btn:active,
.disclaimer-btn.btn:focus,
.disclaimer-btn.btn:focus-visible,
:not(.disclaimer-btn .btn-check) + .disclaimer-btn .btn:active {
  background: #bf8f02;
  border-radius: 0;
  border-color: #bf8f02;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.015em;
  color: #fff;
  box-shadow: none;
  outline: 0;
}

.disclaimer-btn.btn:hover {
  background: #ab8000;
  border-color: #ab8000;
}

.disclaimer-btn.btn.disabled,
.disclaimer-btn.btn:disabled,
fieldset:disabled .disclaimer-btn.btn {
  background: #bf8f02;
  border-color: #bf8f02;
}

/* Hide the default checkbox */
.disclaimer-label input {
  display: none;
}

.disclaimer-label {
  position: relative;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

/* Create a custom checkbox */
.disclaimer-checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 1.3em;
  width: 1.3em;
  background-color: #2196f300;
  border-radius: 0;
  transition: all 0.25s;
}

.disclaimer-label input:checked ~ .disclaimer-checkmark {
  background-color: #bf8f02;
}

.disclaimer-checkmark:after {
  content: "";
  position: absolute;
  transform: rotate(0deg);
  border: 0.1em solid black;
  left: 0;
  top: 0;
  height: 1.3em;
  width: 1.3em;
  border-radius: 0;
  transition: all 0.25s, border-width 0.1s;
}

.disclaimer-label input:checked ~ .disclaimer-checkmark:after {
  left: 42%;
  top: 29%;
  width: 0.25em;
  height: 0.5em;
  border-color: #fff0 white white #fff0;
  border-width: 0 0.15em 0.15em 0;
  border-radius: 0em;
  transform: rotate(45deg);
}



.weight-card-desc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.weight-chart-desc-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.weight-chart-ex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.chart-color {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background: #e4e7ee;
}

.weight-chart-ex p {
  color: #181f37;
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: 1px;
}

.carat-chart-wrapper .apexcharts-tooltip {
  max-width: unset !important;
  padding: 8px !important;
}

.diary-saved-card-img .saved-card-content {
  width: 100%;
}

.diary-delete-icon svg path {
  transition: all 0.3s;
}

.diary-delete-icon:hover svg path {
  fill: #181f37;
}

.delete-modal-btn {
  display: flex;
  justify-content: space-between;
}

.delete-modal-btn .btn {
  width: 49%;
}

.diary-delete-modal {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.diary-delete-modal p {
  font-size: 18px;
  font-family: Montserrat;
  line-height: 39px;
  letter-spacing: 0.18px;
  margin: 0;
}

.diary-report-page .toolcard-action-btn {
  display: flex;
  max-width: fit-content;
}

.diary-page .toolcard-action-btn .btn {
  width: 100px;
}

.icons-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 6px;
}

.diary-delete-icon svg path {
  fill: #757575;
}

.saved-card-content span {
  display: flex;
  gap: 18px;
}
.video-content {
  position: relative;
}
.overlay-content + p {
  filter: blur(3px) !important;
}
.blur p {
  filter: blur(3px) !important;
}

.overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 5px;

  background: rgb(24 31 55 / 80%);
  box-shadow: 3px 3px 20px 0px rgba(0, 0, 0, 0.25);
}
.overlay-content-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  gap: 72px;
  max-width: 550px;
  width: 100%;
}

.video-content {
  position: relative;
}

.overlay-content img {
  width: 75px;
  height: 65px;
}

.overlay-content p {
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-family: Montserrat;
  line-height: 32px;
  letter-spacing: 0.18px;
  max-width: 500px;
  width: 100%;
}
.overlay-content p span {
  font-weight: bold;
}
.overlay-content p > p {
  max-width: 550px;
}
.overlay-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
  position: relative;
  gap: 23px;
}

.overlay-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.overlay-button button {
  width: 220px !important;
}
.saved-card-right {
  display: flex;
  align-items: flex-start;
  gap: 18px;
}
.origin-content {
  background: #fff;
  box-shadow: 3px 3px 20px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 425px;
}

.origin-content-item {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0pc 15px;
}
.origin-loader img {
  width: 120px;
  height: 120px;
}
.origin-loader p {
  color: #000;
  text-align: center;
  font-size: 21px;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 0px;
  letter-spacing: 0.21px;
  text-transform: capitalize;
}
.origin-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.diary-saved-card-img .saved-card-date {
  display: flex;
  column-gap: 6px;
  flex-wrap: wrap;
}

.pdf-btn.btn {
  position: relative;
}



.saved-card-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.diary-edit-icon svg {
  width: 18px;
  height: 18px;
}

.diary-edit-icon {
  transform: translate(-6px, -6px);
}

.face-card-image.animate {
  transition: all 1.3s;
}

.face-card-image.faceup-image-first {
  position: relative;
  z-index: 1;
}

section#colorCauseBox {
  overflow-x: hidden;
}

/* ********************* Rarity card ********************* */
.diary-rarity.text.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.diary-rarity-text-reel-wrapper {
  position: relative;
  padding-left: 30px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  margin: 0;
  color: #181f37;
}

.diary-rarity.text.wrapper p {
  position: relative;
  padding-left: 30px;
}

.diary-rarity.text.wrapper p::before,
.diary-rarity-text-reel-wrapper::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #000;
  left: 0;
  top: 8px;
}

.report-action-dot button#dropdown-basic {
  width: unset !important;
}

.rarity-grade-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rarity-grade-text p {
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px;
  letter-spacing: 0.64px;
  font-family: "Cinzel";

  text-underline-offset: 7px;
  text-decoration-thickness: 1px;
}

.rarity-grade-text span {
  color: #fff;
  text-align: center;
  font-size: 32px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.68px;
  text-transform: uppercase;
}

.rarity-grade::after {
  position: absolute;
  content: "";
  width: 40px;
  height: 48.389px;
  background-image: url(../../assets/images/rarityIcon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  bottom: 20px;
  right: 16px;
  opacity: 0.6;
}

.rarity-grade-point-content p {
  color: #707070;
  position: relative;
  font-size: 12px;
  font-family: Montserrat;
  padding-left: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.12px;
}

.rarity-grade-point-content p span {
  font-weight: 500;
}

.rarity-grade-point-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.rarity-grade-point-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: center;
}

.rarity-grade-divider {
  width: 1px;
  height: 62.65px;
  background: #bdbdbd;
}

.rarity-grade-wrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.rarity-grade-point-content p::before {
  position: absolute;
  content: "";
  width: 2px;
  height: 2px;
  border-radius: 2px;
  background: #707070;
  top: 6px;
  left: 0;
}

.text-url.mobile {
  display: none;
}

.grade-info-text {
  opacity: 0;
  transition: all 1.3s;
}

.grade-info-text.animate {
  opacity: 1 !important;
}

.diary-rarity-text-reel-wrapper span {
  width: fit-content;
  display: inline-block;
  transform: translate(3px, 1.5px);
  height: 16px;
  overflow: hidden;
  margin-right: 2px;
  text-align: right;
}

.diary-card-content p.rarity-link-text {
  font-size: 12px;
}
/* ********************* Rarity card ********************* */

.diary-shape-view.multi-shape-img {
  width: 100%;
}

.diary-origin-youtube {
  width: 100%;
}

.diary-origin-youtube .rarity-link {
  font-size: 16px;
}

.storytelling-upgrade-text {
  font-family: "Montserrat";
  text-align: center;

  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 21.48px;
  max-width: 60%;
}

.storyteller-text-container {
  margin: 20px auto 0px;
}

.storyteller-text-container h5 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 14.63px;
  letter-spacing: 0.48px;
  text-transform: capitalize;
}
.storyteller-text-container h5 a {
  text-decoration: underline;
  color: #bf8f02;
}

.diamond-Storyteller {
  background: #bf8f02 !important;
  color: white !important;
  width: 180px !important;
}

.diamond-storyteller-container {
  display: flex;
  text-align: center;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 14px;
}

.down-arrow {
  display: block;
  margin: auto;
}

.storyteller-languages {
  width: 120px;
  position: relative;
}
.language-btn {
  width: 100%;
  border-color: #bf8f02 !important;
  color: #bf8f02 !important;
  padding-right: 6px !important;
}



.language-btn .horizontal-line {
  height: 16px;
  border-right: 2px solid #bf8f02;
}


.storyteller-languages-list {
  position: absolute;
  background-color: #fff;
  color: #212529;
  border: 2px solid #bf8f02;
  width: 120px;
  z-index: 1;
}
.storyteller-languages-list p {
  font-family: Montserrat;
  font-size: 11.13px;
  font-weight: 500 !important;
  line-height: 14px;
  text-align: left;
  padding: 8px 12px;
  margin: 0px;
}

.storyteller-languages-list p:hover {
  background-color: #bf8f02;
  color: #fff;
}

.selected-language {
  background-color: #bf8f02;
  color: #fff;
}

.diamond-Storyteller svg path {
  fill: white !important;
}

.diamond-progress {
  max-width: 300px;
  width: 100%;
}

.diamond-progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 20px 0px;
}
.diamond-progress .progress-bar {
  background: #bf8f02 !important;
}

.diamond-story-btn {
  color: #d5b65a;
  text-decoration: underline;
  font-size: 14px;
  line-height: 18.22px;
  font-weight: 600;
}

.new-access-popup-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.access-popup-close {
  position: absolute;
  top: 10px;
  right: 20px;
}
.new-access-popup-text button {
  background: #bf8f02 !important;
  color: white !important;
}

.storyLoading-text {
  font-weight: 600;
  color: #cacaca;
  font-size: 14px;
  line-height: 17.07px;
  text-align: center;
}

.storyLoading-progress .progress {
  background: #d9d9d9;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 4px;
  height: 12px;
  background: #ccc;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 20px;
  width: 20px;
  background-color: #171f37;
  border-radius: 50%;
  border: none;
  transition: 0.2s ease-in-out;
}

input[type="range"]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #bf8f02;
  border-radius: 50%;
  border: none;
  transition: 0.2s ease-in-out;
}

input[type="range"]::-webkit-slider-thumb:hover {
  /* box-shadow: 0 0 0 10px rgba(191, 143, 2, 0.1); */
}

input[type="range"]:active::-webkit-slider-thumb,
input[type="range"]:focus::-webkit-slider-thumb {
  /* box-shadow: 0 0 0 13px rgba(191, 143, 2, 0.2); */
}

input[type="range"]::-moz-range-thumb:hover {
  box-shadow: 0 0 0 10px rgba(191, 143, 2, 0.1);
}

input[type="range"]:active::-moz-range-thumb,
input[type="range"]:focus::-moz-range-thumb {
  box-shadow: 0 0 0 13px rgba(191, 143, 2, 0.2);
}

.range {
  position: relative;
}

.dots {
  position: absolute;
  top: 7px;
  left: 0;
  right: 0;
  height: 0;
  display: flex;
  justify-content: space-evenly;
}



#diamondStoryBox h6 {
  font-weight: 600;
}

#diamondStoryBox p {
  font-weight: 400;
}

.diamond-dispclaimer {
  font-size: 12px;
}

/* ***************************************************************************************************** */
/* ******************************************** Media Query ******************************************** */
/* ***************************************************************************************************** */

@media screen and (max-width: 1240px) {
  .second-faceup-inner-text{
    max-width: unset;
  }
  .fluorescence-diamond-bg {
    background-position: 57% 50%;
  }

  .colorCause-card-content {
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
  .colorCause-card-content p {
    max-width: none;
  }

  .diary-card h4 {
    max-width: 100%;
  }
  /* .diary-card-content.diamond-index-card-content {
    flex-direction: column-reverse;
  } */

  .diamond-index-chart-wrapper,
  .index-card-text {
    max-width: 100%;
    gap: 6px;
  }

  .index-average-data h6 {
    font-size: 16px;
  }
  .second-faceup-inner-text p{
    font-size: 14px;
  }

  .faceup-card-content {
    /* flex-direction: column-reverse; */
    align-items: center;
    justify-content: center;
    gap: 27px;
  }

  .faceup-text-wrapper {
    width: 100%;
  }

  .faceup-card-content p {
    max-width: 100%;
  }
}

@media screen and (min-width: 1025px) {
  .diary-page .report-logo img{
    width: 15%;
    height: 15%;
  }
  .diary-report-page .container {
    padding: 0 24px !important;
  }
}

@media screen and (max-width: 1024px) {
  .second-faceup-image-box span{
    /* bottom: 86px;
    right: 99px; */
  }
  .faceup-.second-faceup-card-content-content{
    flex-direction: column;
  }
  .second-faceup-inner-text{
    display: flex;
    /* max-width: unset; */
  }

  /* .diary-title .toolcard-action-btn {
    max-width: 100%;
  } */
  .storyteller-beta p {
    top: 32px;
    /* left: 55px; */
  }
  .dairy-banner {
    height: unset;
    background-position: center;
  }
  .diary-report-page .toolcard-action-btn {
    max-width: 100% !important;
    justify-content: space-between !important;
  }
  .fluorescence-diamond-bg {
    background-position: 52% 50%;
  }

  .diary-character-wrapper {
    flex-wrap: wrap;
    gap: 24px;
  }

  .title-long-width {
    max-width: 100% !important;
  }

  .rarity-grade,
  .rarity-general {
    width: 100%;
  }

  .rarity-info-mob {
    display: block;
    width: 100%;
  }

  .shape-card-content {
    flex-wrap: wrap;
    justify-content: center;
  }

  .shape-card-content p {
    max-width: unset;
  }

  .diary-card-content {
    flex-wrap: wrap;
    justify-content: center;
    /* align-items: center; */
  }

  /* .diary-card-content.colorCause-card-content, */
  .diary-card-content.carat-card-content,
  .diary-card-content.diamond-index-card-content,
  .fluorescence-card-content {
    flex-direction: column-reverse;
  }

  .history-card-content img {
    max-width: unset;
  }

  .diary-card-content p {
    max-width: unset;
    /* width: 300px; */
  }

  .update-origin-loader {
    width: 100%;
  }
  .image-compare {
    transform: translateY(0);
  }

  .clarity-grid-wrapper {
    flex-wrap: wrap;
  }

  .diary-character-img img {
    width: 166px;
    height: 166px;
    object-fit: contain;
  }

  .diary-color-detail {
    max-width: 32%;
  }

  .diary-color-wrapper {
    justify-content: center;
  }

  .carat-heading {
    max-width: unset;
    gap: 20px;
    flex-direction: column-reverse;
  }

  .clarity-box-wrapper {
    max-width: 33.3%;
  }

  .diary-card {
    padding: 48px 24px;
  }

  .famous-card-left {
    width: 100%;
  }

  .famous-card-right {
    width: 100%;
  }

  .diary-wrapper {
    padding-bottom: 80px;
  }

  .mobile-hide-card-btn {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .diary-card h4 {
    max-width: 100%;
  }

  .diary-title-diamond {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
    flex-direction: column-reverse;
  }

  .diamond-diary-text {
    width: 100% !important;
  }

  .diamond_report_btn {
    width: 100%;
  }

  .diamond-new-btn.btn {
    width: unset;
  }

  .diamond-diary-text .diamond_diary_btbtn {
    width: unset !important;
  }

  .diary-tool-grid {
    flex-direction: column;
  }

  .diary-tool-grid .rarity-tool-left {
    max-width: 100%;
  }

  .diary-tool-grid .report-action-btn .btn,
  .diary-tool-grid .diamond_diary_btn,
  .diary-tool-grid .copy-btn-wrapper {
    width: 100% !important;
    flex-wrap: unset;
  }

  .carat-chart-wrapper {
    margin-top: 26px;
  }

  .rarity-grade-wrapper {
    width: 100%;
  }

  .rarity-general .text-url {
    display: none;
  }

  .text-url.mobile {
    width: 100%;
    margin-top: 20px;
    display: block;
  }

  .rarity-grade-point-wrapper {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
  }

  .diary-card-content.polished-card-content {
    gap: 30px;
  }
  .dec-para-full-height {
    height: 320px;
  }
}

@media screen and (max-width: 992px) {
  .faceup-card-content {
    flex-direction: column-reverse;
  }

  .second-faceup-inner-text p{
    font-size: 12px;
  }
  .dairy-banner {
    display: none;
    background-position: unset;
  }
  .diary-mob-banner {
    display: block !important;
    background-size: 100% !important;

    /* height: 336px !important; */
  }
  .saved-card-right {
    gap: 10px;
  }
  .diary-page {
    overflow-x: hidden;
  }
  .fluorescence-diamond-bg {
    background-position: 52% 50%;
  }

  .faceup-card-content {
    gap: 30px;
  }

  .diary-wrapper .rarity-tool-text {
    display: block;
  }

  .faceup-top {
    width: 100%;
  }

  .faceup-frame-wrapper {
    transform: translateX(7%) scale(0.8);
  }

  .faceup-card-main-content {
    width: 100%;
    overflow: hidden;
    gap: 40px;
  }

  .colorCause-card-content {
    padding: 0;
    align-items: center;
  }

  .diary-field {
    max-width: 46%;
  }

  .showall-card-btn {
    display: flex;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .diamond_diary_btn {
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  .diamond_diary_btn .copy-btn-wrapper {
    width: 49%;
  }

  .diamond_diary_btn button.outline-btn.btn.btn-primary {
    width: 100%;
  }

  .scroll-up2 {
    position: fixed;
    right: 0%;
    bottom: 90px;
    z-index: 500;
  }
  .scroll-up2 svg {
    height: 42px !important;
    width: 42px !important;
  }

  .scroll-up-share2 {
    position: fixed;
    right: 0%;
    bottom: 120px;
    z-index: 500;
  }
  .scroll-up-share2 svg {
    height: 42px !important;
    width: 42px !important;
  }
}
@media screen and (max-width: 991px) {
  .second-faceup-inner-text{
    max-width: 56%;
    margin-top: 20px !important;
    margin: auto;
  }
  .second-faceup-inner-text p{
    font-size: 14px;
  }
 
  .second-faceup-card-content{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
 .second-faceup-image-container-mbl{
   margin-right: -50px;
  }

  .second-faceup-image-container{
      padding: 0px 20px 30px 30px;
  }
  .overlay-item img {
    width: 30px;
    height: 30px;
  }
  .overlay-content-container,
  .overlay-content p,
  .overlay-item {
    max-width: 425px;
  }
  .overlay-content-container {
    gap: 60px;
  }
  .overlay-content p {
    font-size: 14px;
  }
  .overlay-button {
    flex-wrap: wrap;
  }
  .overlay-button button {
    max-width: 100% !important;
    width: 100% !important;
  }
  .faceup-frame-content {
    position: relative;
    height: 165px;
  }
  .faceup-discover {
    height: 240px;
  }

  .faceup-frame-wrapper {
    position: absolute;
    transform: translateX(-50%) scale(0.8);
  }
}
@media screen and (max-width: 768px) {
  .second-faceup-inner-text{
    max-width: 80%;
    margin-top: 20px !important;
    margin: auto;
  }
  .second-faceup-image-box span{
    /* right: 156px;
    bottom: 90px; */
  }
  .second-faceup-card-content{
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  /* .faceup-frame-content {
    height: 170px;
  } */

  .faceup-discover {
    height: 170px;
  }

  .second-faceup-inner-text p{
    font-size: 12px;
  }
 .second-faceup-image-container-mbl{
   margin-right: -50px;
  }

  .second-faceup-image-container{
      padding: 0px 0px 20px 70px;
  }
  .diary-page .report-logo img{
    width: 30%;
    height: 30%;
  }
  .diary-page .report-action-btn button span {
    display: none;
  }
  .diary-page .report-action-btn {
    flex-wrap: nowrap;
    width: fit-content;
  }
  .diary-title .toolcard-action-btn,
  .diary-tool-grid .diamond_diary_btn {
    width: fit-content !important;
  }
  .diary-title .copy-btn-wrapper .btn {
    width: 100% !important;
  }
  .overlay-content-container {
    top: 80%;
  }
  .blur {
    margin-top: 90px;
  }

  .blur p {
    /* padding-top: 90px; */
  }
  .diary-page .toolcard-action-btn .btn {
    width: 52px;
  }
  .diamond-dispclaimer {
    font-size: 10px;
  }
  .diamond-Storyteller {
    width: unset !important;
  }

  .storyteller-text-container h5 {
    font-size: 12px;
  }

  .storyteller-text-container h5:last-child {
    margin-bottom: 0px;
  }
  /* .storytelling-upgrade-text{
    font-size: 10px;
  } */
  .dec-para-full-height {
    height: 420px;
  }
}

@media screen and (max-width: 767px) {
  .report-action-btn button span {
    display: none;
  }
  .saved-card-wrapper {
    padding: 16px;
  }
  .diary-content .saved-card-wrapper {
    padding-left: 8px;
    padding-right: 8px;
  }
  .diary-saved-card-img {
    width: calc(100% - 60px);
    gap: 8px;
  }
  .rarity-tool-right .report-action-btn.diamond_diary_btn {
    display: flex;
    width: fit-content !important;
  }

  .fluorescence-diamond-bg {
    background-position: 58% 50%;
  }

  .diary-field:nth-child(1),
  .diary-field:nth-child(2) {
    max-width: 46%;
  }

  .diary-character-content.desktop {
    display: none;
  }

  .diary-character-content.mobile {
    display: flex;
    gap: 16px;
  }

  .diary-shape-view.multi-shape-img img {
    height: 120px;
  }

  .diary-shape-view.multi-shape-img .diary-shape-img-pair {
    height: 148px;
  }

  .diary-title .copy-btn-wrapper,
  .diary-report-page .report-action-btn > .btn:nth-child(1),
  .diary-report-page .report-action-btn > .btn:nth-child(2),
  .diary-report-page .copy-btn-wrapper,
  .diary-report-page .toolcard-action-btn > .outline-btn.btn {
    width: 49%;
  }
  .diary-title .copy-btn-wrapper .btn {
    width: 100%;
  }

  .diary-report-page .toolcard-action-btn {
    /* max-width: unset;
    column-gap: unset;
    justify-content: space-between; */
    /* width: fit-content; */
    width: 100% !important;
    flex-wrap: nowrap;
  }

  .rarity-grade-point-wrapper {
    flex-direction: column;
    gap: 8px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .rarity-grade-divider {
    display: none;
  }
  .overlay-content p {
    font-size: 14px;
    line-height: 21px;
    padding: 0px 8px;
  }
  .overlay-content img {
    width: 35px;
    height: 30px;
  }
  .overlay-button {
    flex-wrap: wrap;
    width: 100%;
    gap: 12px;
  }

  .overlay-content-container {
    width: 100%;
    top: 80%;
    gap: 90px;
  }

  .overlay-button button {
    width: 100% !important;
    max-width: 100% !important;
  }
  .blur {
    margin-top: 90px;
  }
  .blur p {
    /* padding-top: 90px; */
  }

  .rarity-grade-text {
    transform: scale(0.8);
  }

  .rarity-grade {
    height: 200px;
  }

  .rarity-grade::after {
    bottom: 10px;
  }
}

@media screen and (max-width: 599px) {
  .diary-card-content .weight-chart-ex p {
    font-size: 14px;
  }
  .fluorescence-diamond-bg {
    background-position: 53% 50%;
  }

  .diary-input-desc {
    padding: 16px;
  }

  .diary-input-content .diary-card {
    padding: 64px 42px;
  }

  .diary-color-detail,
  .clarity-box-wrapper {
    max-width: 49%;
  }

  .diary-input-content .error-text {
    font-size: 10px;
  }
  .overlay-content-container,
  .overlay-item {
    max-width: 100% !important;
    gap: 10px;
    padding-top: 10px;
  }
  .rarity-grade-text {
    transform: scale(0.7);
  }
}

@media screen and (max-width: 480px) {
  .second-faceup-inner-text{
    max-width: 97%;
    margin-top: 20px !important;
    margin: auto;
  }
  .second-faceup-card-content{
    display: flex;
    flex-direction: column;
  }
 .second-faceup-image-container-mbl{
   margin-right: -50px;
  }

  .second-faceup-image-container{
      padding: 0px 10px 15px 80px;
  }

  .beta-top {
    display: none !important;
  }
  .beta-top-mob {
    display: flex;
    position: absolute;
    top: -15px;
    left: 0px;
    font-size: 10px;
    font-weight: 600;
  }
  .fluorescence-diamond-bg {
    background-position: 58% 50%;
  }

  section#surfaceAreaBox {
    overflow-x: hidden;
  }

  .second-faceup-inner-text h5{
    font-size: 32px;
    margin-top: 12px;
  }
  .second-faceup-inner-text p{
    line-height: 15px;
  }



  .faceup-card-content {
    width: 100%;
    align-items: center;
    gap: 10px;
    padding-top: 20px;
  }

  .faceup-card-main-content {
    gap: 30px;
  }

  .faceup-frame-wrapper {
    /* transform: translateX(-3%) scale(0.7); */
    /* transform: translateX(-7.5%) scale(0.85); */
  }

  .diamondMMheight {
    rotate: -90deg;
    translate: 25px;
  }

  .diary-field {
    max-width: 100%;
  }

  .diary-field:nth-child(1),
  .diary-field:nth-child(2) {
    max-width: 45%;
  }
  .overlay-content-container {
    gap: 35px;
  }
  .diary-content .saved-card-img img {
    height: 46px;
  }
  .diary-content .diary-saved-card-img {
    width: calc(100% - 49px);
  }
  .mob-rarity-heading {
    font-size: 22px !important;
  }
  .diary-mob-banner h1 {
    font-size: 18px !important;
    max-width: 230px !important;
  }
  .dec-para-full-height {
    height: 650px;
  }
}


@media screen and (max-width: 390px) {
  .second-faceup-inner-text{
    max-width: 100%;
  }
  .second-faceup-inner-text h5{
    font-size: 28px;
    margin-top: 12px;
  }
  .second-faceup-inner-text p{
    font-size: 10px;
    line-height: 15px;
  }

  .diary-mob-banner h1 {
    font-size: 16px !important;
    max-width: 230px !important;
  }
}

@media screen and (max-width: 375px) {

  .diary-title h1 {
    font-size: 22px;
  }


  .diary-mob-banner h1 {


    font-size: 16px !important;
    max-width: 230px !important;
  }
  .beta-top {
    left: -84%;
  }
}
@media screen and (max-width: 360px) {
  .diary-form-content .error-text {
    bottom: -31px;
    line-height: 14px;
  }

  .diary-field h6 {
    font-size: 16px;
  }
  .overlay-content-container {
    gap: 30px;
  }

  .diary-mob-banner h1 {

    font-size: 12px !important;
    max-width: 170px !important;
  }
}

/* ***************************************************************************************************** */
/* ******************************************** @keyframes ******************************************** */
/* ***************************************************************************************************** */

@keyframes shift-left {
  0% {
    left: -20;
  }

  50% {
    left: -15px;
  }

  100% {
    left: -20px;
  }
}

/* buttons on mobile view */

@media screen and (max-width: 320px) {
  .diary-tool-grid .report-action-btn .btn,
  .diary-tool-grid .diamond_diary_btn,
  .diary-tool-grid .copy-btn-wrapper {
    flex-wrap: wrap;
  }

  .diamond_diary_btn {
    flex-wrap: wrap;
  }

  .diamond-new-report.btn {
    width: 100%;
  }

  .diamond_diary_btn .copy-btn-wrapper {
    width: 100%;
  }
}

/* @supports (-webkit-touch-callout: none) { */

/* / CSS specific to iOS devices /  */
/* .rarity-text-link {
      display: none;
    }
} */
/* modal inputs */

.Crop-Controls {
  display: flex;
  /* gap: 14px; */
  width: 100%;
  justify-content: space-between;
}


.Crop-Controls div {
  width: 48%;
}
input#scale-input,
input#rotate-input {
  border: 1px solid #bf8f02;
  padding: 4px 10px;
  border-radius: 4px;
  width: 100%;
}

.cropedImg canvas {
  border: 1px solid #bf8f02 !important;
  border-radius: 4px;
}

.crop-btn button {
  width: 204px;
  height: 44px;
  position: relative;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.16px;
  color: #f2f5ff;
  background: #bf8f02;
  border: none;
  box-shadow: none;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-open {
  overflow: unset !important;
}
