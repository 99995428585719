

.diary-card.rename-form {
  box-shadow: unset;
  padding: unset;
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
}

.diary-card.rename-form .btn {
  margin-top: 20px;
}

.save-notification-wrapper {
  position: fixed;
  left: 24px;
  bottom: 44px;
  max-width: 400px;
  width: 100%;
  padding: 20px;
  border-radius: 6px;
  background: #181f37;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
}

.save-notification-wrapper p {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.87);
  margin: 0;
}

.save-notification-wrapper span {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 1px;
  color: #bf8f02;
  cursor: pointer;
}

.diary-origin-video-modal .modal-body {
  padding: 0;
}

.diary-origin-video-modal .modal-dialog.modal-lg {
  max-width: 932px;
}

.diary-origin-video-modal .modal-header .btn-close {
  background-color: transparent;
  top: -25px;
  right: 9px;
  color: #fff;
  filter: invert(1);
}

@media screen and (max-width: 767px) {
  .save-notification-wrapper {
    max-width: 320px;
    left: 16px;
  }

  .diary-origin-video-modal iframe#originYoutubeVideo {
    height: 360px;
  }
}
