/* history */

/* history */

.faceup-right-header .dropdown .btn {
  width: 15px;
}

.faceup-input-desc {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-out;
  margin-bottom: 30px;
}

.faceup-input-desc.slide-in {
  max-height: 500px;
}

.faceup-input-desc.slide-out {
  opacity: 0;
  max-height: 0;
  margin-bottom: 5px;
  padding-top: 0;
  padding-bottom: 0;
}
.true-faceup-tool {
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 12px;
}
.faceup-tooltip {
  top: 18px;
  right: 42%;
}
.width-icon {
  position: absolute;
  right: 21px;
  top: 3px;
}
.length-icon {
  position: absolute;
  right: 22px;
  top: 2px;
}
/* shape page css */

.share-face-up-content-wrapper {
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: #fff;
  max-width: 900px;
  width: 100%;
  height: 440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.share-face-up-content-wrapper-gia {
  max-width: 900px;
  width: 100%;
}
.share-face-up-container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  gap: 50px;
  background-color: #fbfcff;
  height: 100vh;
}

.share-face-up-header {
  margin-top: 52px;
}

.share-face-up-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
  /* background-color: #ffffff; */
  padding: 0px 30px 0px;
  /* border-radius: 10px; */
  position: relative;
  /* width: 80%; */
}

.share-face-up-content p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  letter-spacing: 0.01em;
  /* text-align: left; */
}

.share-face-up-content p span {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  letter-spacing: 0.01em;
}

.share-face-up-content h6 {
  font-family: Montserrat;
  /* font-size: 39.3px; */
  font-size: 36px;
  font-weight: 600;
  line-height: 39.3px;
  letter-spacing: 0.01em;
  position: relative;
  /* text-align: left; */
}

.share-face-up-content-header {
  margin-top: 30px;
  text-align: center;
}

.share-face-up-content-header h6 {
  font-weight: 600;
}

.share-face-up-content-header p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.004em;
  text-align: center;
  margin-bottom: 5px;
}

.share-face-up-content-header span {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.004em;
  text-align: center;
  color: gray;
}

.share-face-up-content-image {
  /* width: 112.14px;
  height: 120.68px; */
  margin-bottom: 30px;
}

.share-face-up-content-image img {
  width: 100%;
  height: 100%;
}

.share-face-up-header img {
  width: 200px;
  height: 80px;
  object-fit: contain;
}

.share-face-up-footer {
  /* margin-top: 30px; */
  margin-bottom: 10px;
}

.share-face-up-footer p {
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #000000;
  margin-bottom: 0px;
}
/* shape page css */

.true-face-up-access {
  height: 100vh;
}
.discover-weight-frame {
  display: flex;
  justify-content: center;
  align-items: center;
}

.discover-weight-faceup-image img {
  display: flex;
  justify-content: center;
  width: 110px;
  height: 118px;
}

.discover-weight-faceup-image .image-shadow{
  filter: drop-shadow(-2px 2px 4px rgba(0, 0, 0, 0.25));

}



.discover-weight-input-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
.true-face-history-title h1 {
  /* font-family: Montserrat;
  font-size: 26px;
  font-weight: 600;
  line-height: 39.01px;
  letter-spacing: 0.01em;
  color: #181f37;
  text-transform: capitalize; */
}

.weight-discover-btn {
  margin-top: 30px;
  justify-content: center;
}

.true-face-up-tab .tab-content {
  padding: 30px 20px 20px;
}

.true-face-up-tab .nav-tabs .nav-link {
  text-transform: uppercase;
}

.true-face-up-content h4 {
  font-family: Montserrat;
  /* font-size: 32px;
  font-weight: 600;
  line-height: 39.01px;
  letter-spacing: 0.01em; */
  /* text-align: left; */
  /* color: #181f37; */

  color: #0d1949;
  font-size: 26px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  margin: 0;
  text-transform: capitalize;
}

.true-face-up-content p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.16px;
  color: #181f37;
  margin-bottom: 5px;
}

.true-face-up-desc-first-box p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.16px;
  color: #181f37;
}

/* .true-face-up-desc-first-box span {
  display: none;
} */

.true-face-up-tab1 h6 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  letter-spacing: 0.16px;
  color: #181f37;
  text-align: center;
  margin-bottom: 40px;
}

.gia-below-text {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.7px;
  color: #333333;
  text-align: center;
}
.true-face-up-gia-input {
  margin-bottom: 20px;
}

.true-face-up-gia-input input {
  /* text-align: center;
  border-bottom: 2px solid;
  font-family: Montserrat;
  font-size: 18px;
  line-height: 31px;
  font-weight: 400;
  color: #333333; */
}

.true-face-up-main {
  gap: 0px;
  padding: 20px 10px 0px;
}

.true-face-up-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  width: 100%;
}

.true-face-image-container {
  padding: 10px;
}

.newtrue-face-up-container img {
  padding-top: 10px !important;
  padding-left: 7px !important;
  padding-right: 7px !important;
  padding-bottom: 60px !important;
}

.true-face-up-result-main {
  text-align: center;
  /* margin-bottom: 100px; */
}
.true-faceup-newui {
  margin-bottom: 0px;
}

.faceup-report-bottom {
  margin-top: 10px !important;
  font-size: 14px !important;
}

.true-face-up-result {
  display: flex;
  width: 100%;
  gap: 10px;
  padding: 0px 10px;
  /* margin-top: 50px; */
  flex-direction: column-reverse;
  align-items: center;
}

.true-face-up-result-gia {
  padding: 0px 40px;
  justify-content: space-between;
}

.true-face-up-result-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.true-face-up-result-right h6{
  margin-top: 50px;
  font-weight: 600;
}

.true-face-up-result-left-gia {
  align-items: center;
  /* margin: auto; */
  /* max-width: 411px; */
  /* padding: unset !important; */
  /* margin-top: 40px; */
  /* padding: 0px 5%; */
}

.visual-presence-carat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  /* margin: 25px 0px; */
}
.visual-presence-carat h4 {
  margin-bottom: 6px !important;
}

.true-face-btn-new {
  margin: auto;
}

.true-face-up-result-heading .rarity-top-heading {
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 400;
  line-height: 39.01px;
  letter-spacing: 0.32px;
  color: #181f37;
  padding-bottom: 50px;
  text-align: center;
  text-transform: uppercase;
}



.true-face-up-result-left p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.16px;
  color: #181f37;
  margin-bottom: 0px;
  /* text-transform: uppercase; */
}

.true-face-up-result-left div {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.16px;
  color: #181f37;
  text-align: left;
  /* text-transform: uppercase; */
}
.true-face-up-result-left p span {
  font-weight: 600;
}
.true-face-up-result-left div span {
  font-weight: 600;
}

.true-face-up-result-left div strong {
  font-weight: 400;
  white-space: nowrap;
}

.true-face-up-btn {
  display: flex;
  /* margin-top: 30px; */
  text-align: center;
  /* justify-content: center; */
}

.true-face-up-btn button {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0.16px;
  color: #bf8f02;
  border-radius: 0px;
  border: 1px solid #bf8f02 !important;
  padding: 0px 20px;
  margin: 20px auto 30px;
}

/* hover */
.true-face-up-btn button:hover {
  background-color: #bf8f02 !important;
  color: white !important;
}

.true-face-up-btn button:hover svg path {
  fill: #fff;
}

.discover-weight-top {
  text-align: center;
}

.discover-weight-top h5 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.16px;
  color: #000000;
  text-transform: uppercase;
}

.discover-weight-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: 30px;
}

.discover-weight-content p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  letter-spacing: 0.16px;
  color: #000000;
  text-align: center;
  text-transform: uppercase;
}

.discover-weight-grid {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.discover-weight-wrapper {
  width: 110px;
  height: 110px;
}

.discover-weight-select-size {
  text-align: center;
}

.discover-weight-container {
  margin: auto;
}
.discover-weight-select-size p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  letter-spacing: 0.16px;
  color: #000000;
  text-transform: uppercase;
  margin: 40px 0px;
  text-align: center;
}

.discover-weight-box {
  display: flex;
  align-items: center;
  gap: 30px;
  max-width: 269px;
}

.discover-weight-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  max-width: 269px;
  margin: auto;
}

.discover-weight-result {
  position: relative;
}
.discover-weight-trueFace {
  position: absolute;
  top: 34px;
  right: -15px;
}
.share-weight-trueFace {
  position: absolute;
  top: -2px;
  right: -15px;
}

.discover-weight-result p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  letter-spacing: 0.01em;
  text-align: center;
  margin-bottom: 8px;
}

.discover-weight-info {
  display: none;
}

.discover-weight-trueFace:hover .discover-weight-info {
  display: block;
}

.discover-weight-result h5 {
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 400;
  line-height: 39.01px;
  letter-spacing: 0.32px;
  color: #000000;
  text-transform: uppercase;
}

.discover-weight-result h6 {
  font-family: Montserrat;
  /* font-size: 43.3px; */
  font-size: 36px;
  font-weight: 600;
  line-height: 39.3px;
  letter-spacing: 0.01em;
  margin-bottom: 50px;
}

.true-face-up-desc-first-box p span {
  display: none;
}
.mobile-info-box {
  display: none !important;
}

.true-face-up-gia-input ::placeholder {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.16px;
  color: #939cbc !important;
}

.true-face-up-gia-input input:disabled {
  background: #fff;
}

.faceup-length-input input {
  text-align: center !important;
}

.round-length {
  right: 38px
}
.round-length-div
{
padding-left: 28px;
}

.round-length-div input {
  padding: 0px;
}

.round-length-div input::placeholder {
  padding-left: 30px;
}

.round-length-max {
  padding-left: 0px;
}
.round-length-max input {
  padding: 0px;
}
.round-length-max input::placeholder {
  padding-left: 28px;
  /* padding: unset; */
}

.round-length-max .width-icon {
  right: 27px ;
}




.faceup-length-input ::placeholder {
  padding-left: 15px;
  /* margin-right: 25px !important; */
}
.faceup-width-input ::placeholder {
  padding-left: 12px;
  /* margin-right: 35px !important; */
}
.faceup-width-input input {
  text-align: center !important;
}

.center-gia-input .true-face-up-gia-input ::placeholder {
  text-align: left;
}
.center-gia-input .true-face-up-gia-input input {
  text-align: left;
}

.discover-weight-color-box img {
  height: 48px;
  width: 48px;
}
.discover-weight-color-box-extra img {
  height: 56px;
  width: 56px;
}

.true-faceup-frame-wrapper p {
  display: none;
}

.true-faceup-frame-wrapper .diamondTxt1 {
  text-align: left;
  display: none;
}

.true-faceup-frame-wrapper .diamondTxt2 {
  display: none;
}
.share-faceup-frame-wrapper .diamondTxt1 {
  left: 3px;
}

.discover-weight-info {
  min-width: 268px;
  border-radius: 0px 0px 3px 3px;
  background: #fff;
  box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.2);
  padding: 16px;
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 12px;
  /* display: none; */
}

.discover-weight-info p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.16px;
  color: #000000;
  text-align: justify;
}
.true-face-up-container .processBtn.btn {
  width: 100%;
}
.true-face-up-container .processBtn.btn:disabled {
  opacity: 0.3;
}

.true-face-up-container .new-rarity-tab .nav-tabs .nav-link {
  box-shadow: none;
}

.true-face-up-container .new-rarity-tab .nav-tabs .nav-link.active {
  box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.25), 2px 0px 4px rgba(0, 0, 0, 0.25);
}

.share-face-up-footer-mobile {
  display: none;
}

.faceup-frame-weight-container {
  position: absolute;
  left: 78px;
}

.faceup-frame-weight-content {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.faceup-frame-weight-content p {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.16px;
  text-align: center;
  background-color: #fff;
  /* padding: 2px 6px; */
  min-width: 44.67px;
  min-height: 22px;
  margin-bottom: 0px;
  position: absolute;
  z-index: 99;
}

.top-image-arrow p {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.16px;
  text-align: center;
  background-color: #fff;
  /* padding: 2px 6px; */
  min-width: 44.67px;
  min-height: 22px;
  margin-bottom: 0px;
  position: absolute;
}

.top-image-arrow-section {
  position: absolute;
  left: 119px;
  top: -24px;
}

.thin-arrow-section {
  left: 131px !important;
}

.marquise-arrow-section {
  left: 143px !important;
}

.top-image-arrow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
    top: 30px;
}

.img-arrowTop {

  height: 10px;
  position: absolute;
  top: -2px;
  left: 50%;
  background: red;
  display: flex;
  align-items: center;
  justify-content: center;

}



.faceup-frame-left-arrow img {
  height: 118px;
}

.weight-top-arrow img {
  height: unset;
  /* width: 88.34px; */
}
.thin-arrow-image img {
  width: 88px !important;
}

.marquise-arrow-image img {
  /* width: 62px !important; */
}
.share-footer-date {
  font-size: 14px !important;
}

.share-footer-date-mob {
  display: none;
}


.img-arrowTop img {
  position: absolute;
  top: 10px;
  width: 108px;
  height: 10px;
  /* object-fit: cover; */
}

.img-arrowTop span
 {
  position: absolute;
  top: 4px;
  /* padding: 2px 8px; */
  background: white;
  text-align: center;
  font-size: 15px;
  min-width: 44.67px;
  min-height: 22px;
 }

 .img-arrowLeft {

  position: absolute;
  top: 25px;
  height: 118px;
  display: flex;
  justify-content: center;
  align-items: center;

 }

 .img-arrowLeft span {
    position: absolute;
    background: white;
    left: -49px;
   font-size: 15px;
    /* padding: 2px 8px; */
    text-align: center;
    min-width: 44.67px;
    min-height: 22px;
 }

 .true-face-left span{
    min-width: 44.67px;
    min-height: 22px;
 }

.img-arrowLeft img {

  position: absolute;
  top: 0;
  left: -83px;
  height: 100%;

}

.img-arrowLeft1 img{
  position: absolute;
  top: 0;
  left: -78px;
}

.cushionleft {  
   
  position: absolute;
  top: 28px;
  display: flex; 
  height: 125px;

}

.cushionleft img{
height: 100%;
}

.cushionleft span{
  /* position: absolute;
  left: -30px;
  background: white;
  top: 40px;
  min-width: 15px; */
  text-align: center;

}

.faceup-report {
  font-size: 14px !important;
font-weight: 400 !important;
}


.standard-faceup h4{
  font-family: Montserrat;
font-weight: 600;
font-size: 38.3px;
line-height: 39.3px;
letter-spacing: 1%;

}

.diamond-faceup-container{
  padding: 80px 10px;
}

@media screen and (max-width: 991px) {
  .true-faceup-frame-wrapper {
    transform: translateX(6%) scale(0.8);
  }

  /* .true-face-up-result{
    gap: 80px;
  } */

.true-heading-mob {
    padding: 10px !important;
   }
  .share-footer-date {
   display: none;
  }
  .share-footer-date-mob {
   display: flex;
   font-size: 12px !important;
   /* line-height: 28px; */
   margin-bottom: 0px ;
  }
  .true-face-up-gia-input {
    margin: auto;
  }
  .true-face-up-result-left div {
    font-size: 16px;
    line-height: 24px;
  }
  .faceup-frame-weight-container {
    left: 102px;
  }
  .true-faceup-frame-content {
    position: unset;
    display: flex;
    padding-right: 3rem;
    justify-content: center;
    text-align: center;
  }
  .discover-weight-container {
    margin-top: 20px;
  }
  .true-face-up-btn {
    margin-top: 20px;
    justify-content: center;
  }
  .true-face-up-result {
    margin-top: 0px;
  }
  .true-face-up-tab .tab-content {
    padding: 20px 20px 20px;
  }
  .discover-weight-result h6 {
    margin-bottom: 0px;
  }
  .share-face-up-content-wrapper {
    width: 64%;
  }
  .true-face-up-title-container-mobile {
    flex-direction: column-reverse;
  }

  .newtrue-face-up-container img {
    padding-top: 10px !important;
    padding-left: 7px !important;
    padding-right: 7px !important;
  }
  .discover-weight-box {
    padding: 0px;
    gap: 20px;
    margin: auto;
  }

  .discover-weight-info {
    top: 11px;
    right: 8px;
    left: unset;
  }

  .mobile-info-box {
    display: flex !important;
    justify-content: space-between;
    margin-bottom: 25px;
  }
  .true-face-up-desc-first-box p {
    line-height: 28px;
    padding-right: 25px;
  }

  .true-face-up-desc-first-box p span {
    display: inline;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.07px;
    letter-spacing: 0.14px;
    color: #fff;
    background-color: #bf8f02;
    padding: 3px 6px;
  }

  .true-face-up-result-heading .rarity-top-heading {
    font-size: 18px;
    font-weight: 500;
    line-height: 21.94px;
    letter-spacing: 0.18px;
    padding-bottom: 0px;
  }
  .true-face-up-result-left {
    padding: 20px 30px 5px;
  }
  .true-face-up-result-left p {
    font-family: Montserrat;
    /* font-size: 12px; */
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.12px;
    text-transform: none;
    text-align: center;
  }

  .true-face-up-result-left-gia div {
    text-align: center;
  }

  .true-face-up-result-left-gia p {
    margin-bottom: 5px;
  }
  .true-faceup-frame-wrapper .diamondTxt1 {
    display: none;
  }
  .true-faceup-frame-wrapper .diamondTxt2 {
    display: none;
  }
  .true-faceup-frame-wrapper span {
    font-weight: 600;
  }
  .true-faceup-frame-wrapper p {
    display: block;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    color: #000000;
    text-align: center;
    text-transform: capitalize;
  }

  .true-face-up-result {
    flex-direction: column-reverse;
  }

  .diamond-weight-container .rarity-gia-btn {
    max-width: 240px;
    margin: auto;
  }
  .true-face-up-gia-input .true-faceup-gia-input {
    margin: auto;
  }

  .discover-weight-select-size {
    margin-top: 40px;
  }

  .discover-weight-select-size p {
    margin: 0px;
  }
  .true-face-up-container {
    padding: 0px !important;
  }
  .discover-weight-color-box img {
    height: 40px;
    width: 40px;
  }
  .discover-weight-color-box-extra {
    gap: 0px !important;
  }
  .discover-weight-color-box-extra img {
    height: 46px;
    width: 46px;
  }
  .discover-weight-color-box {
    gap: 5px;
  }

  .discover-weight-top h5 {
    line-height: 19.5px;
    text-transform: capitalize;
  }
  .discover-weight-result h5 {
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.2px;
    text-transform: capitalize;
  }
  .discover-weight-result h6 {
    font-size: 28px;
    line-height: 28.05px;
    letter-spacing: 0.28px;
  }

  .true-face-up-tab1 h6 {
    margin-bottom: 20px;
  }
  .discover-weight-grid {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    place-items: center;
  }
  .discover-weight-wrapper {
    height: 82.02px;
    width: 82.02px;
  }
  .true-face-up-result-main {
    margin-bottom: unset;
  }
}


@media screen and (max-width: 768px) {
  .diamond-faceup-container{
    padding: 40px 10px;
  }
  .img-arrowLeft span{
    font-family: Montserrat;
font-weight: 500;
font-size: 10.72px;
line-height: 12.79px;
letter-spacing: 0.1em;
text-align: center;
text-transform: uppercase;
min-height: 11.99px;
min-width: 25.67px;

left: -40px;

  }
  .img-arrowTop span
 {
  position: absolute;
 
 
  background: white;
  text-align: center;
  min-width: 25.67px;
  min-height: 11.99px;
  font-family: Montserrat;
font-weight: 500;
font-size: 10.72px;
line-height: 12.79px;
letter-spacing: 0.1em;
text-align: center;
text-transform: uppercase;
top: 3px;

 }
  .round-length-max input{
   padding-left: 8px;
  }
  .standard-faceup h4{
    font-family: Montserrat;
  font-weight: 600;
  font-size: 26.3px;
  /* line-height: 29.3px; */
  /* letter-spacing: 1%; */
  
  }
  .true-face-up-result-right h6{
    margin-top: 0px;
  }
  .faceup-report-bottom {
    margin-top: 25px !important;
    font-size: 12px !important;
  }
  .faceup-report {
    line-height: 20px !important;
    font-weight: 400 !important;
    margin-top: 30px;
    text-align: center !important;
  }

  .share-faceup-result {
    padding: 10px !important;
  }

  .true-face-up-btn button {
    margin: 10px auto 0px;
  }

  .share-face-up-content-header {
    margin-top: 45px;
  }

  .discover-weight-faceup-image {
    height: 100px;
  }
  .discover-weight-faceup-image img {
    height: 100%;
  }
  .faceup-frame-left-arrow img {
    height: 100px;
  }
  .share-face-up-content-wrapper-gia {
    gap: 0px;
  }
  .share-face-up-content {
    padding: 0px 10px 0px;
  }
  .share-face-up-footer {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .true-faceup-tool {
    justify-content: flex-start;
    margin-top: unset;
  }
  .true-face-up-result-main {
    margin-bottom: unset;
  }
  .true-face-up-result-left-text {
    margin-top: 20px;
    text-align: center;
    padding: 0px 10px;
  }
  .true-face-up-result-left-text p {
    font-size: 16px !important;
    text-align: center;
  }
  .true-face-up-result-left-text p span {
    font-size: 16px;
  }
  .rarity-top-header {
    padding: 20px 20px;
  }

  .true-face-up-gia-input {
    padding: 0px !important;
  }

  .true-faceup-frame-content {
    padding-right: unset;
    position: unset;
    display: flex;
    justify-content: center;
  }
  .discover-weight-container {
    margin-top: 20px;
  }
  .true-face-up-btn {
    margin-top: 20px;
    justify-content: center;
  }
  .true-face-up-result {
    margin-top: 0px;
  }
  .true-face-up-tab .tab-content {
    padding: 20px 20px 20px;
  }
  .discover-weight-result h6 {
    margin-bottom: 0px;
  }
  .share-face-up-content-wrapper {
    width: 81%;
  }
  .true-face-up-title-container-mobile {
    flex-direction: column-reverse;
  }

  .newtrue-face-up-container img {
    padding-top: 10px !important;
    padding-left: 7px !important;
    padding-right: 7px !important;
  }
  .discover-weight-box {
    padding: 0px;
    gap: 20px;
    margin: auto;
  }

  .discover-weight-info {
    top: 11px;
    right: 8px;
    left: unset;
  }

  .mobile-info-box {
    display: flex !important;
    margin-bottom: 25px;
    justify-content: space-between;
  }
  .true-face-up-desc-first-box p {
    line-height: 28px;
    padding-right: 25px;
  }

  .true-face-up-desc-first-box p span {
    display: inline;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.07px;
    letter-spacing: 0.14px;
    color: #fff;
    background-color: #bf8f02;
    padding: 3px 6px;
  }

  .true-face-up-result-heading .rarity-top-heading {
    font-size: 18px;
    font-weight: 500;
    line-height: 21.94px;
    letter-spacing: 0.18px;
    padding-bottom: 0px;
  }
  .true-face-up-result-left {
    padding: 40px 0px 5px;
  }
  .true-face-up-result-left div {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    /* line-height: 20px; */
    letter-spacing: 0.12px;
    text-transform: none;
    text-align: center;
  }
  .true-faceup-frame-wrapper .diamondTxt1 {
    display: none;
  }
  .true-faceup-frame-wrapper .diamondTxt2 {
    display: none;
  }
  .true-faceup-frame-wrapper span {
    font-weight: 600;
  }
  .true-faceup-frame-wrapper p {
    display: block;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    color: #000000;
    text-align: center;
    text-transform: capitalize;
  }
  .true-faceup-frame-wrapper {
    transform: translateX(0%) scale(0.8);
  }

  .true-face-up-result {
    flex-direction: column-reverse;
  }
  .true-face-up-tab .nav {
    height: 52px;
  }

  .true-face-up-tab .nav-tabs .nav-link,
  .true-face-up-tab .nav-tabs .nav-link.active {
    text-transform: unset;
    width: 150px;
    text-wrap: wrap;
    background: none !important;
    border: none !important;
    box-shadow: none !important;
    margin: auto;
    text-transform: uppercase;
  }
  .true-face-up-tab li.nav-item:has(.nav-link.active) {
    color: #bf8f02;
    border-bottom: 4px solid #bf8f02 !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .true-face-up-tab li.nav-item:has(.nav-link) {
    border-radius: 0;
    margin: 0;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
    color: #939cbc;
    height: 52px;
    border-bottom: 4px solid transparent;
  }

  .diamond-weight-container .rarity-gia-btn {
    max-width: 240px;
    margin: auto;
  }
  .true-face-up-gia-input .true-faceup-gia-input {
    margin: auto;
  }

  .discover-weight-select-size {
    margin-top: 40px;
  }

  .discover-weight-select-size p {
    /* display: none; */
  }
  .true-face-up-container {
    padding: 0px !important;
  }
  .discover-weight-color-box img {
    height: 40px;
    width: 40px;
  }
  .discover-weight-color-box-extra {
    gap: 0px !important;
  }
  .discover-weight-color-box-extra img {
    height: 46px;
    width: 46px;
  }
  .discover-weight-color-box {
    gap: 5px;
  }

  .discover-weight-top h5 {
    line-height: 19.5px;
    text-transform: capitalize;
  }
  .discover-weight-result h5 {
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.2px;
    text-transform: capitalize;
  }
  .discover-weight-result h6 {
    font-size: 28px;
    line-height: 28.05px;
    letter-spacing: 0.28px;
  }

  .true-face-up-tab1 h6 {
    margin-bottom: 20px;
  }
  .discover-weight-grid {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    place-items: center;
  }
  .discover-weight-wrapper {
    height: 82.02px;
    width: 82.02px;
  }
}

@media screen and (max-width: 480px) {
  .faceup-report-bottom {
    margin-top: 25px !important;
    font-size: 12px !important;
  }
  /* .share-face-up-content-image .thin-arrow-section {
    left: 141px !important;
  } */
  .thin-arrow-section {
    left: 140px !important;
  }
  .thin-arrow-image img {
    width: 70px !important;
  }
  .top-image-arrow p {
   
    margin-bottom: 0px !important;
    font-family: Montserrat;
font-weight: 500;
font-size: 10.72px !important;
line-height: 12.79px !important;
text-align: center;
text-transform: uppercase;
min-height: 11.99px;
min-width: 25.67px;

  }
  .faceup-frame-weight-content p {
    
    margin-bottom: 0px !important;
    font-family: Montserrat;
font-weight: 500;
font-size: 10.72px !important;
line-height: 12.79px !important;
letter-spacing: 0%;
text-align: center;
text-transform: uppercase;
min-height: 11.99px;
min-width: 25.67px;

  }
  .share-face-up-content-image .faceup-frame-left-arrow img {
    height: 88px;
  }

  .share-face-up-content-image .weight-top-arrow img {
    width: 98px;
  }
  .share-face-up-content-image .top-image-arrow-section {
    left: 128px;
  }
  /* .share-face-up-content-image .top-image-arrow img {
    width: 68px !important;
  } */
  .share-face-up-footer-mobile {
    display: block;
    text-align: center;
  }
  .share-face-up-footer-mobile p {
    font-size: 11px;
    margin-bottom: 0px;
  }

  .share-face-up-footer {
    display: none;
  }

  .discover-weight-frame {
    height: 150px;
  }

  /* shape page css */
  .share-face-up-content-wrapper {
    width: 90%;
    height: 460px;
    gap: 5px;
  }
  .share-face-up-content-report-text {
    max-width: 230px;
  }

  .share-face-up-content {
    padding: 25px 10px 30px;
    text-align: center;
  }

  .share-face-up-content p {
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.01em;
  }
  .share-face-up-content h6 {
    font-size: 28px;
    font-weight: 600;
    line-height: 28.05px;
    letter-spacing: 0.01em;
    margin-bottom: 0px;
  }

  .share-face-up-content-image img {
    /* width: 100%; */
    height: 100%;
  }
  .share-face-up-header img {
    width: 150px;
    height: 60px;
    object-fit: contain;
  }

  .share-face-up-footer p {
    font-size: 11px;
  }

  .share-face-up-content-header {
    margin-top: 30px;
  }

  .share-face-up-content-header p {
    font-size: 14px;
  }

  /* shape page css */

  /* shape page css */

  .newtrue-face-up-container img {
    padding-top: 10px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .discover-weight-info {
    top: 11px;
    right: 8px;
    left: unset;
  }

  .mobile-info-box {
    display: flex !important;
    margin-bottom: 25px;
    justify-content: space-between;
  }
  .true-face-up-desc-first-box p {
    line-height: 28px;
    padding-right: 25px;
    line-height: 28px;
        padding-right: 25px;
        min-height: 56px;
        display: flex;
        justify-content: left;
        align-items: center;
  }

  .true-face-up-desc-first-box p span {
    display: inline;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.07px;
    letter-spacing: 0.14px;
    color: #fff;
    background-color: #bf8f02;
    padding: 3px 6px;
  }

  .true-face-up-result-heading .rarity-top-heading {
    font-size: 18px;
    font-weight: 500;
    line-height: 21.94px;
    letter-spacing: 0.18px;
    padding-bottom: 0px;
  }
  .true-face-up-result-left {
    padding: 40px 0px 5px;
  }
  .true-face-up-result-left-gia p {
    margin-bottom: 5px;
  }
  .true-face-up-result-left p {
    font-family: Montserrat;
    /* font-size: 12px; */
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.12px;
    text-transform: none;
  }
  .true-faceup-frame-wrapper .diamondTxt1 {
    display: none;
  }
  .true-faceup-frame-wrapper .diamondTxt2 {
    display: none;
  }
  .true-faceup-frame-wrapper span {
    font-weight: 600;
  }
  .true-faceup-frame-wrapper p {
    display: block;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    color: #000000;
    text-align: center;
    text-transform: capitalize;
  }

  .true-faceup-frame-wrapper {
    transform: translateX(0%) scale(0.8);
  }

  .true-face-up-result {
    flex-direction: column-reverse;
  }
  .true-face-up-tab .nav {
    height: 52px;
  }

  .true-face-up-tab .nav-tabs .nav-link,
  .true-face-up-tab .nav-tabs .nav-link.active {
    text-transform: unset;
    width: 150px;
    text-wrap: wrap;
    background: none !important;
    border: none !important;
    box-shadow: none !important;
    margin: auto;
    text-transform: uppercase;
  }
  .true-face-up-tab li.nav-item:has(.nav-link.active) {
    color: #bf8f02;
    border-bottom: 4px solid #bf8f02 !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .true-face-up-tab li.nav-item:has(.nav-link) {
    border-radius: 0;
    margin: 0;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
    color: #939cbc;
    height: 52px;
    border-bottom: 4px solid transparent;
  }

  .diamond-weight-container .rarity-gia-btn {
    max-width: 240px;
    margin: auto;
  }
  .true-face-up-gia-input .true-faceup-gia-input {
    margin: auto;
  }

  .discover-weight-select-size {
    margin-top: 40px;
  }

  .discover-weight-select-size p {
    /* display: none; */
  }
  .true-face-up-container {
    padding: 0px !important;
  }
  .discover-weight-color-box img {
    height: 40px;
    width: 40px;
  }
  .discover-weight-color-box-extra {
    gap: 0px !important;
  }
  .discover-weight-color-box-extra img {
    height: 46px;
    width: 46px;
  }
  .discover-weight-color-box {
    gap: 5px;
  }

  .discover-weight-top h5 {
    line-height: 19.5px;
    text-transform: capitalize;
  }
  .discover-weight-result h5 {
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.2px;
    text-transform: capitalize;
  }
  .discover-weight-result h6 {
    font-size: 28px;
    line-height: 28.05px;
    letter-spacing: 0.28px;
  }

  .true-face-up-tab1 h6 {
    margin-bottom: 20px;
  }
  .discover-weight-grid {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    place-items: center;
  }
  .discover-weight-wrapper {
    height: 82.02px;
    width: 82.02px;
  }
}

@media screen and (max-width: 425px) {
  .true-face-up-result-left-gia {
    padding: 20px 0px 0px;
  }
  .share-face-up-footer p {
    font-size: 11px;
  }
}

@media screen and (max-width: 391px) {
  .true-face-up-result-left-gia {
    padding: 20px 0px 0px;
  }
  .share-face-up-footer p {
    font-size: 11px;
  }

  .true-faceup-frame-wrapper {
    transform: translateX(0%) scale(0.8);
  }
}
